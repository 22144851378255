import React, { Component } from "react";
import { Link } from "react-router-dom";
import ToastDemo from "../Helper/toaster";
import api from "../../Environment";
import { withToastManager } from "react-toast-notifications";

const $ = window.$;
class SpaceListView extends Component {
  state = {
    wishlistData: null,
    loading: true
  };
  componentDidMount() {
    // this.homePageApiCall();
    window.scrollTo(0, 0);
  }

  favourite = (event, data) => {
    console.log(data);
    event.preventDefault();
    if (this.props.status == "host") {
        ToastDemo(
            this.props.toastManager,
            "You can't add to wishlist, login as User",
            "error"
        );
    } else {
        let addWishlist = {
            space_id: data.space_id,
            clear_all_status: 0
        };
        api.postMethod("wishlist_operations", addWishlist).then(
            response => {
                if (response.data.success) {
                  this.setState({
                    wishlistData: response.data.data,
                    loading: false
                  });

                  ToastDemo(
                    this.props.toastManager,
                    response.data.message,
                    "success"
                  );
                } else {
                  const check = this.checkLoginUser(response.data);
                  if (check) {
                    ToastDemo(
                      this.props.toastManager,
                      "Please login to save the details",
                      "error"
                    );
                  } else {
                    ToastDemo(this.props.toastManager, response.data.error, "error");
                  }
                }
            }
        );
    }
  };

  render() {
    const { spaceList } = this.props;
    return (
      <section className="sm-padding">
        <div className="container">
          <div className="row mb-5 row-margin-zero">
            <div className="col-md-12">
              <h2 className="title_main no-margin">{spaceList.title}</h2>
              <i className="border-title"></i>
            </div>
          </div>
          <div className="row">
            {spaceList.data.length > 0
              ? spaceList.data.map(singleSpace => (
                  <div
                    className="col-xl-3 col-lg-6 col-md-6 mb-4"
                    key={singleSpace.space_id}
                  >
                    <div className="workspace-card">
                      <div>
                        <div className="card_img wish-list">
                        <Link
                            to={`/single-space/${singleSpace.space_id}`}>
                          <img
                            src={singleSpace.space_picture}
                            alt=""
                            className="single-space-img"
                          /></Link>
                         
                        <Link
                        to="#"
                        className="fas fa-heart wish_list_icon"
                        onClick={e =>
                            this.favourite(e, singleSpace)
                        }
                    >
                        {singleSpace.wishlist_status ? (
                            <div>
                                <i className="fa fa-check" />
                                &nbsp; saved
                            </div>
                        ) : (
                            <div>
                                <i className="far fa-heart" />
                                &nbsp; save
                            </div>
                        )}
                    </Link>
                        </div>
                        <div className="card_details">
                          <h4 className="card-title">
                            {singleSpace.space_name}
                          </h4>
                          <p className="card-desc">
                            {singleSpace.space_location}
                          </p>
                          <p className="card-desc">
                          <span className="highlight-txt">{singleSpace.space_type == "shared_space"
                              ? "Shared Space"
                              : ""}
                            {singleSpace.space_type == "private_space"
                              ? "Private Space"
                              : ""}
                            {singleSpace.space_type == "own_space"
                              ? "Own Space"
                              : ""}</span>  
                            | {singleSpace.per_day_formatted}{" "}
                            {singleSpace.per_day_symbol}
                          </p>
                          <div className="rating">
                            <i className="fa fa-star active"></i>
                            <i className="fa fa-star active"></i>
                            <i className="fa fa-star active"></i>
                            <i className="fa fa-star active"></i>
                            <i className="fa fa-star active"></i>
                            <span className="badge badge-success pl-2">
                              5.0
                            </span>
                          </div>
                          <Link
                            to={`/single-space/${singleSpace.space_id}`}
                            className="btn btn-primary btn-block mt-3"
                          >
                            More Details
                          </Link>
                        </div>
                      </div>
                      {/*<div className="single-space-btn">  
                      </div> */}
                    </div>
                  </div>
                ))
              : ""}
          </div>
          <Link
            className="btn btn-primary"
            to={`/view-all/${spaceList.url_type}`}
          >
            Show All
          </Link>
        </div>
      </section>
    );
  }
}

export default withToastManager(SpaceListView);
