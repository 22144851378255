import React, { Component } from "react";
import { DatePicker, Calendar } from "@y0c/react-datepicker";
import dayjs from "dayjs";
import api from "../../Environment";
import { translate, t } from "react-multi-lang";

const onlyMonth = {
  month: "numeric"
};
const onlyYear = {
  year: "numeric"
};
const onlyDate = {
  day: "numeric"
};

class SpaceAvailablity extends Component {
  state = {
    selected: [],
    num: 1,
    availabilities: null,
    loading: true
  };
  componentDidMount() {
    const inputData = {
      space_id: this.props.space_id,
      month: new Date().toLocaleDateString("en-US", onlyMonth),
      year: new Date().toLocaleDateString("en-US", onlyYear),
      loops: 2
    };
    this.getAvailabilityApiCall(inputData);
  }
  getAvailabilityApiCall = inputData => {
    api.postMethod("space_availability_view", inputData).then(response => {
      if (response.data.success) {
        this.setState({
          availabilities: response.data.data,
          loading: false
        });
        setTimeout(() => {
          let array = [];
          this.state.availabilities.data.map(availability => {
            if (availability.is_available == 0)
              array.push(dayjs(availability.date));
            this.setState({ selected: array });
          });
        }, 3000);

        if (this.state.num > 1) console.log(this.state.selected[0].c);
      }
    });
  };

  customDayText = (date: dayjs.Dayjs) => {
    // for test (year, month remove)
    // const classMap = {
    //   "01": "Abc",
    //   "02": "DEF"
    // // };
    // console.log("DAte in custom text", dayjs(date).format("DD"));
    // console.log("DAte in custom text", dayjs(date).format("MM"));
    let classMap = {};

    this.state.availabilities.data.map(availability => {
      if (availability.date == dayjs(date).format("YYYY-MM-DD")) {
        if (availability.is_available == 1) {
          classMap[dayjs(date).format("DD")] =
            "Av:" + availability.seats_available;
        } else {
          classMap[dayjs(date).format("DD")] =
            "NA";
        }
      }
    });
    console.log("classMap", classMap);
    return classMap[dayjs(date).format("DD")];
  };

  render() {
    const { selected, loading, availabilities } = this.state;
    return (
      <div className="col-md-12 mt-4 big-calendar">
        <h2 className="title_main no-margin">{t("availablity_calendar")}</h2>
        <div className="row mt-4">
          <div className="col-md-12 mb-5 row-margin-zero">
            <div className="workspace-card only-pricing">
              {loading ? (
                t("loading")
              ) : (
                <Calendar
                  {...this.props}
                  selected={selected}
                  // onChange={this.handleChange}
                  customDayText={this.customDayText}
                  showMonthCnt={1}
                  readOnly={true}
                />
              )}
            </div>
            <p className="address mt-3">{t("availablity_calendar_note")}</p>
          </div>
        </div>
      </div>
    );
  }
}

export default translate(SpaceAvailablity);
