import React, { Component } from "react";
import { Link } from "react-router-dom";
import { translate, t } from "react-multi-lang";

class ProfileHistorySection extends Component {
  state = {};
  render() {
    const { histories } = this.props;
    return (
      <div className="row">
        <div className="col-md-12">
          {histories.length > 0
            ? histories.map(history => (
                <div className="user-booking-list">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="user-img">
                        <img src={history.space_picture} alt="user" />
                      </div>
                      <div className="booking-details">
                        <h3 className="card-title">{history.space_name}</h3>
                        <p className="card-desc">{history.space_location}</p>
                      </div>
                    </div>
                    <div className="col-md-4 text-center left-allign">
                      <p className="card-desc">
                        {t("move_in_date")}
                        <strong> : {history.checkin}</strong>
                      </p>
                      <p className="card-desc">
                        {t("number_of_people")}
                        <strong> : {history.total_seats}</strong>
                      </p>
                    </div>
                    <div className="col-md-4 text-center mt-50 left-align">
                      <Link
                        className="btn btn-primary"
                        to={`single-booking-history/${history.booking_id}`}
                      >
                        {t("view")}
                      </Link>
                    </div>
                  </div>
                </div>
              ))
            : t("no_data_found")}
        </div>
      </div>
    );
  }
}

export default translate(ProfileHistorySection);
