import React, { Component } from "react";
import api from "../../Environment";
import { withToastManager } from "react-toast-notifications";
import ToastDemo from "../Helper/toaster";
import { translate, t } from "react-multi-lang";

class ChangePassword extends Component {
  state = {
    loadingChangePassContent: null,
    buttonDisableChangePass: false,
    data: {}
  };
  getChangePassInput = ({ currentTarget: input }) => {
    const changePassInput = { ...this.state.changePassInput };
    changePassInput[input.name] = input.value;
    this.setState({ changePassInput });
  };
  changePassword = event => {
    event.preventDefault();
    this.setState({
      loadingChangePassContent: "Loading... Please wait..",
      buttonDisableChangePass: true
    });

    api
      .postMethod("change_password", this.state.changePassInput)
      .then(response => {
        if (response.data.success === true) {
          ToastDemo(this.props.toastManager, response.data.message, "success");
          this.setState({
            loadingChangePassContent: null,
            buttonDisableChangePass: false
          });
        } else {
          ToastDemo(this.props.toastManager, response.data.error, "error");
          this.setState({
            loadingChangePassContent: null,
            buttonDisableChangePass: false
          });
        }
      });
  };
  render() {
    const { data } = this.state;
    return (
      <div className="row">
        <div className="col-xl-8 col-lg-8 col-md-12">
          <div className="contact">
            <form onSubmit={this.changePassword}>
              <div className="form-group">
                <label>{t("old_password")}</label>
                <input
                  type="password"
                  className="form-control"
                  placeholder={t("old_password_placeholder")}
                  name="old_password"
                  value={data.old_password}
                  onChange={this.getChangePassInput}
                />
              </div>
              <div className="form-group">
                <label>{t("new_password")}</label>
                <input
                  type="password"
                  className="form-control"
                  placeholder={t("new_password_placeholder")}
                  name="password"
                  value={data.password}
                  onChange={this.getChangePassInput}
                />
              </div>
              <div className="form-group">
                <label>{t("confirm_password")}</label>
                <input
                  type="password"
                  className="form-control"
                  placeholder={t("confirm_address_placeholder")}
                  name="password_confirmation"
                  value={data.password_confirmation}
                  onChange={this.getChangePassInput}
                />
              </div>
              <div className="row">
                <div className="col-md-12 mt-3">
                  <button
                    type="submit"
                    href="#"
                    className="btn btn-primary btn-lg btn-block"
                    disabled={this.state.buttonDisableChangePass}
                  >
                    {this.state.loadingChangePassContent != null
                      ? this.state.loadingChangePassContent
                      : t("reset_password")}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default withToastManager(translate(ChangePassword));
