import React, { Component } from "react";
import { Link } from "react-router-dom";
import Helper from "../Helper/helper";
import { translate, t } from "react-multi-lang";

class ProviderProfile extends Helper {
  state = {
    loading: true,
    data: null
  };

  componentDidMount() {
    this.getProviderProfile(this.props.match.params.id);
  }
  render() {
    const { loading, data } = this.state;
    return (
      <section className="sm-padding">
            <div className="container">
                <div className="row mb-5">
                    <div className="col-md-12 text-center">
                        <h2 className="title_main no-margin">{t("profile")}</h2>
                        <i className="border-title mx-auto"></i>
                    </div>
                </div>

                {loading ? ("") : Object.keys(data).length > 0 ? (
                <div className="row">
                    <div className="col-md-12">
                        <div className="profile-card">
                            <div className="row">
                                <div className="profile-desc col-md-6">
                                    <div className="profile-img">
                                        <img src={data.picture}
                                            alt={data.username} />
                                    </div>
                                    <div className="profile-details">
                                        <h4 className="user-name">{data.username}</h4>
                                        {data.full_address != "" ? (
                                        <p className="card-desc"><strong>{data.full_address}</strong></p>
                                        ) : ("")}
                                    </div>
                                    <h5 className="top lh-1-4">{data.description}</h5>
                                </div>
                                <div className="verified col-md-6">
                                    <h4 className="card-title mb-3">{t("verified_information")}</h4>
                                    <p className="card-desc mb-2"> {t("email_address")}
                                        <span className="float-right">
                                        {data.is_verified ? (
                                            <i className="far fa-check-circle text-success"></i>
                                        ) : (
                                            <i className="far fa-times-circle text-warning"></i>
                                        )}
                                        </span>
                                    </p>
                                    <p className="card-desc">Phone Number
                                        <span className="float-right">
                                        {data.mobile ? (
                                            <i className="far fa-check-circle text-success"></i>
                                        ) : (
                                            <i className="far fa-times-circle text-warning"></i>
                                        )}
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                ) : (
                    ""
                  )}
            </div>
        </section>
    );
  }
}

export default translate(ProviderProfile);
