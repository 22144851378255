import React, { Component } from "react";
import { GoogleApiWrapper, InfoWindow, Marker, Map } from "google-maps-react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import Helper from "./helper";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

const mapStyles = {
  width: "100%",
  height: "100%"
};

export class MapContainer extends Helper {
  state = {
    showingInfoWindow: false,
    activeMarker: {},
    selectedPlace: {},
    content: {},
    loading: true
  };

  onMarkerClick = (props, marker, e) =>
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
      loading: false
    });

  onClose = props => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null,
        loading: true
      });
    }
  };

  render() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true
    };
    const data = "";
    const price = "234";
    const rate = "as";
    const { loading } = this.state;

    return (
      <Map
        google={this.props.google}
        zoom={14}
        style={mapStyles}
        initialCenter={{
          lat: 12.8401025,
          lng: 77.66391016
        }}
      >
        {this.props.data.map(content => (
          <Marker
            onClick={this.onMarkerClick}
            name={content.host_name}
            title={content.host_name}
            content={content}
            label={{
              text: content.per_day_formatted,
              fontSize: "16px",
              fontWeight: "bold",
              color: "#fff"
            }}
            icon={{
              url: window.location.origin + "/assests/mapicons/black-map.svg"
            }}
            // icon={{
            //   url:
            //     'data:image/svg+xml;utf-8, \
            //               <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"> \
            //               <g fill-rule="evenodd"> \
            //       <text font-size="8.276" font-weight="bold"> \
            //           <tspan x="4" y="13"> \
            //           </tspan> \
            //       </text> \
            //       <text font-size="8.276" font-weight="bold">\
            //           <tspan x=".37" y="8"></tspan>\
            //       </text>\
            //   </g>\
            //               </svg>'
            // }}
            position={{
              lat: content.latitude,
              lng: content.longitude
            }}
          />
        ))}
        <InfoWindow
          marker={this.state.activeMarker}
          visible={this.state.showingInfoWindow}
          onClose={this.onClose}
        >
          {loading ? (
            ""
          ) : (
            <div className="row">
              <div
                className="col-md-12 mb-2"
                key={this.state.activeMarker.content.space_id}
              >
                <div className="workspace-card bg-shadow-1">
                  <a
                    href={`/single-space/${this.state.activeMarker.content.space_id}`}
                  >
                    <div className="card_img">
                    <img
                        src={this.state.activeMarker.content.space_picture}
                        alt=""
                     />                   
                    </div>
                    <div className="card_details">
                      <h4 className="card-title">
                        {this.state.activeMarker.content.space_name}
                      </h4>
                      <p className="card-desc">
                        {this.state.activeMarker.content.space_location}
                      </p>
                      <h5 className="price mt-3">Starting Price</h5>
                      <p className="card-desc">
                        <span>
                          {this.state.activeMarker.content.per_day_symbol}
                        </span>
                        <strong>
                          <span className="float-right">
                            {this.state.activeMarker.content.per_day_formatted}
                          </span>
                        </strong>
                      </p>
                      {/* <p className="card-desc no-margin">
                                  Per Month{" "}
                                  <strong>
                                    <span className="float-right">$ 1500</span>
                                  </strong>
                                </p> */}
                    </div>
                  </a>
                </div>
              </div>
            </div>
          )}
        </InfoWindow>
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyARW_YBJ-OU_RfSlMLlvLBHJaG-W_EQv4I"
})(MapContainer);
