import React, { Component } from "react";
import { translate, t } from "react-multi-lang";

class StaticContent extends Component {
  state = {};
  render() {
    return (
      <section className="sm-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="title_main no-margin">{t("our_number_says")}</h2>
              <i className="border-title"></i>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-12 text-center">
              <div className="row">
                <div className="col-xl-3 col-lg-6 col-md-6">
                  <div className="our-number-list">
                    <h2>
                      <span
                        className="count_up_stat"
                        data-to="90"
                        data-speed="1500"
                      >
                        90
                      </span>
                      <span className="inc">+</span>
                    </h2>
                    <span className="our-numbers__info">{t("coworkers")}</span>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-6 col-md-6">
                  <div className="our-number-list">
                    <h2>
                      <span
                        className="count_up_stat"
                        data-to="180"
                        data-speed="1500"
                      >
                        180
                      </span>
                      <span className="inc">+</span>
                    </h2>
                    <span className="our-numbers__info">{t("rooms")}</span>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-6 col-md-6">
                  <div className="our-number-list">
                    <h2>
                      <span
                        className="count_up_stat"
                        data-to="260"
                        data-speed="1500"
                      >
                        260
                      </span>
                      <span className="inc">+</span>
                    </h2>
                    <span className="our-numbers__info">{t("phone_booths")}</span>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-6 col-md-6">
                  <div className="our-number-list">
                    <h2>
                      <span
                        className="count_up_stat"
                        data-to="340"
                        data-speed="1500"
                      >
                        340
                      </span>
                      <span className="inc">+</span>
                    </h2>
                    <span className="our-numbers__info">{t("desks")}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default translate(StaticContent);
