import React, { Component } from "react";
import Helper from "../Helper/helper";
import api from "../../Environment";
import ToastDemo from "../Helper/toaster";
import { withToastManager } from "react-toast-notifications";
import { translate, t } from "react-multi-lang";

const $ = window.$;

class Register extends Helper {
  state = {
    data: {
      name: "",
      email: "",
      password: "",
      device_type: "web",
      device_token: "123466",
      login_by: "manual"
    },
    loadingContent: null,
    buttonDisable: false
  };
  handleSubmit = event => {
    event.preventDefault();
    const path = this.props.location;
    this.setState({
      loadingContent: "Loading... Please wait..",
      buttonDisable: true
    });
    api
      .postMethod("register", this.state.data)
      .then(response => {
        if (response.data.success === true) {
          localStorage.setItem("userId", response.data.data.user_id);
          localStorage.setItem("accessToken", response.data.data.token);
          localStorage.setItem("userLoginStatus", true);
          localStorage.setItem("user_picture", response.data.data.picture);
          localStorage.setItem("username", response.data.data.username);
          // window.location = path ? path.from.pathname : "/home";
          ToastDemo(this.props.toastManager, response.data.message, "success");
          this.setState({ loadingContent: null, buttonDisable: false });
          $("#signup").modal("hide");
          if (path) {
            this.props.history.push(path.pathname);
          } else {
            this.props.history.push("/");
          }
          // window.locatiom = path
          //   ? this.props.history.push(path.from.pathname)
          //   : this.props.history.push("/home");
          // this.props.history.push("/home");
        } else {
          ToastDemo(this.props.toastManager, response.data.error, "error");
          // $("#signup").modal("hide");
          this.setState({ loadingContent: null, buttonDisable: false });
        }
      })
      .catch(function(error) {});
  };
  render() {
    const { data } = this.state;
    return (
      <div
        className="modal fade bd-signup-modal-lg"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
        id="signup"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12 mb-5">
                  <h5 className="modal-title text-center">{t("welcome")}!</h5>
                  <i className="border-title mx-auto"></i>
                </div>
                <div className="col-md-6 pr-5">
                  <div className="signup-img">
                    <img
                      src={window.location.origin + "/assests/img/sign.svg"}
                      alt="User Login"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <form onSubmit={this.handleSubmit}>
                    <div className="form-group">
                      <label className="col-form-label">{t("name")}</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={t("name_placeholder")}
                        name="name"
                        value={data.name}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label className="col-form-label">{t("email_address")}</label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder={t("email_address_placeholder")}
                        name="email"
                        value={data.email}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label className="col-form-label">{t("password")}</label>
                      <input
                        type="password"
                        className="form-control"
                        placeholder={t("password_placeholder")}
                        name="password"
                        value={data.password}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label className="col-form-label">{t("confirm_password")}</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={t("confirm_address_placeholder")}
                        name="confirm_passsword"
                        value={data.confirm_passsword}
                        onChange={this.handleChange}
                      />
                    </div>

                    <button
                      type="submit"
                      className="btn btn-primary btn-block mt-4"
                      disabled={this.state.buttonDisable}
                    >
                      {this.state.loadingContent != null
                        ? this.state.loadingContent
                        : t("signup")}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withToastManager(translate(Register));
