import React, { Component } from "react";
import Login from "../../Auth/login";
import Register from "../../Auth/register";
import { Link } from "react-router-dom";
import "./index.css";
import { apiConstants } from "../../Constant/constants";
import { withToastManager } from "react-toast-notifications";
import ToastDemo from "../../Helper/toaster";
import api from "../../../Environment";
import { translate, t } from "react-multi-lang";

class HomeHeader extends Component {
  state = {
    formData: {
      latitude: "",
      longitude: ""
    },
    suggestions: null,
    loadingsuggestion: true,
    displaysuggestion: "none"
  };

  handleSearchChange = ({ currentTarget: input }) => {
    console.log("Input:", input);
    if (input.value != "") {
      this.setState({ displaysuggestion: "block" });
    } else {
      this.setState({ displaysuggestion: "none" });
    }
    api
      .postMethod("spaces_search", { key: input.value })
      .then(response => {
        if (response.data.success) {
          console.log("REsponse", response.data);
          this.setState({
            suggestions: response.data.data,
            loadingsuggestion: false
          });
        } else {
        }
      })
      .catch(function(error) {});
  };

  handleSearchResult = (event, space_id) => {
    event.preventDefault();
    this.props.history.push(`/single-space/${space_id}`);
    this.setState({
      suggestions: null,
      loadingsuggestion: true,
      displaysuggestion: "none"
    });
  };

  render() {
    const { suggestions, loadingsuggestion } = this.state;
    let renderContent;
    
    console.log(localStorage.getItem("userLoginStatus"));
    if (!localStorage.getItem("userLoginStatus")) {
      renderContent = (
        <React.Fragment>
          <li className="nav-item">
            <a
              className="nav-link"
              href="#"
              data-toggle="modal"
              data-target=".bd-login-modal-lg"
            >
              {t("login")}
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              href="#"
              data-toggle="modal"
              data-target=".bd-signup-modal-lg"
            >
              {t("signup")}
            </a>
          </li>
        </React.Fragment>
      );
    } else {
      renderContent = (
        <React.Fragment>
          <li className="nav-item dropdown user-profile">
            <a
              className="dropdown-toggle user-link"
              href="#"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <img src={localStorage.getItem("user_picture")} />
              <span className="user-nav pl-2">
                {localStorage.getItem("username")}
              </span>
            </a>
            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
              <Link className="dropdown-item" to={"/user-profile"}>
                {t("profile")}
              </Link>
              <Link className="dropdown-item" to={"/booking-history"}>
                {t("booking_list")}
              </Link>
              <Link className="dropdown-item" to={"/logout"}>
                {t("logout")}
              </Link>
            </div>
          </li>
        </React.Fragment>
      );
    }
    return (
      <div>
        <header>
          <nav className="navbar navbar-expand-lg">
            <div className="container no-padding">
              <Link className="navbar-brand" to={"/"}>
                <img
                  className="main_logo"
                  src={window.location.origin + "/assests/img/appicon.png"}
                />
              </Link>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"><i className="fas fa-bars"></i></span>
              </button>
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav ml-auto main-menu">
                  <li className="nav-item">
                    {/* <a
                      className="nav-search"
                      href="#"
                      data-toggle="modal"
                      data-target="#fullModal"
                    >
                      <i className="fas fa-search"></i>
                   </a> */}
                    <a className="nav-search">
                      <form className="search-suggestion-form">
                        <div className="search-input-container center">
                          <div className="search-input-container__inner">
                            <input
                              type="text"
                              name="search"
                              placeholder={t("search_with_space_name")}
                              className="form-control search-form"
                              onChange={this.handleSearchChange}
                            />

                            <div
                              className="suggestions-container center"
                              style={{
                                display: this.state.displaysuggestion
                              }}
                            >
                              <ul className="list-unstyled">
                                {loadingsuggestion
                                  ? t("loading")
                                  : suggestions.length > 0
                                  ? suggestions.map((suggestion, index) => (
                                      <li
                                        onClick={event =>
                                          this.handleSearchResult(
                                            event,
                                            suggestion.space_id
                                          )
                                        }
                                      >
                                        <span>{suggestion.space_name}</span>
                                      </li>
                                    ))
                                  : t("no_result")}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </form>
                    </a>
                  </li>

                  {localStorage.getItem("userLoginStatus") ? (
                    // <li className="nav-item">
                    //   <Link className="nav-link" to={"/booking-history"}>
                    //     {t("upcoming_bookings")}
                    //   </Link>
                    // </li>
                    <li className="nav-item dropdown user-profile mr-2">
                      <a
                        className="dropdown-toggle user-link"
                        href="#"
                        id="navbarDropdown"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <span className="user-nav pl-2">
                          Bookings
                        </span>
                      </a>
                      <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                        <Link className="dropdown-item" to={"/booking-upcoming"}>
                        {t("upcoming_bookings")}
                        </Link>
                        <Link className="dropdown-item" to={"/booking-history"}>
                        {t("bookings_history")}
                        </Link>
                      </div>
                    </li>
                  ) : (
                    ""
                  )}

                  {renderContent}
                </ul>
              </div>
            </div>
          </nav>
        </header>
        <Login {...this.props} />
        <Register {...this.props} />
      </div>
    );
  }
}

export default withToastManager(translate(HomeHeader));
