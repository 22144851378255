import React, { Component } from "react";
import Helper from "../Helper/helper";
import { Link } from "react-router-dom";
import ToastDemo from "../Helper/toaster";
import api from "../../Environment";
import { withToastManager } from "react-toast-notifications";
import EditProfile from "./editProfile";
import UploadPhoto from "./uploadPhoto";
import ProfileHistorySection from "./profileHistorySection";
import Cards from "./cards";
import ChangePassword from "./changePassword";
import DeleteAccount from "./deleteAccount";
import { Elements, StripeProvider } from "react-stripe-elements";
import { translate, t } from "react-multi-lang";

class UserProfile extends Helper {
  state = {
    data: {},
    loading: true,
    buttonDisabled: false,
    loadingEditContent: null,
    imageButtonDisabled: false,
    loadingImageContent: null,
    loadingCardList: true,
    cardList: null,
    displayAddCard: "none",
    activeNavClassName: "editProfile",
    loadingChangePassContent: null,
    buttonDisableChangePass: false,
    changePassInput: {},
    deleteAccountInput: {},
    loadingDeleteAccContent: null,
    buttonDisableDeleteAcc: false,
    changeImageInput: {},
    loadingChangeImageContent: null,
    buttonDisableChangeImage: false,
    imagePreviewUrl: null,
    histories: null,
    loadingHistory: true,
    profileError: null
  };
  componentDidMount() {
    this.getUserDetails();
  }

  getCardListDetails = event => {
    event.preventDefault();
    this.setState({ activeNavClassName: "payment" });
    this.getCardList();
  };

  changeNav = (event, activeNavClassName) => {
    event.preventDefault();
    this.setState({ activeNavClassName });
    if (activeNavClassName == "bookinglist") {
      this.historyAPICAll();
    }
    console.log("CAlled;", this.state.activeNavClassName);
  };

  historyAPICAll = () => {
    api.postMethod("bookings_history").then(response => {
      if (response.data.success === true) {
        this.setState({ histories: response.data.data, loadingHistory: false });
      }
    });
  };

  displayAddCard = () => {
    this.setState({ displayAddCard: "block" });
  };

  render() {
    const {
      data,
      loading,
      loadingCardList,
      cardList,
      displayAddCard,
      activeNavClassName,
      imagePreviewUrl,
      histories,
      loadingHistory,
      profileError
    } = this.state;

    if (profileError != null) {
      ToastDemo(this.props.toastManager, profileError, "error");
      this.props.history.push("/logout");
    }

    return (
      <section className="sm-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="title_main no-margin">{t("user_information")}</h2>
              <i className="border-title mb-5"></i>
            </div>
          </div>
          <div className="user-details">
            <div className="row">
              <div className="col-xl-3 col-lg-3 col-md-12">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item">
                    <a
                      className={
                        activeNavClassName == "editProfile"
                          ? "nav-link active show"
                          : "nav-link"
                      }
                      data-toggle="tab"
                      href="#edit-profile"
                      role="tab"
                      aria-controls="profile"
                      onClick={event => this.changeNav(event, "editProfile")}
                    >
                      {t("edit_profile")}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={
                        activeNavClassName == "photos"
                          ? "nav-link active show"
                          : "nav-link"
                      }
                      data-toggle="tab"
                      href="#photos"
                      role="tab"
                      aria-controls="profile"
                      onClick={event => this.changeNav(event, "photos")}
                    >
                      {t("photos")}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={
                        activeNavClassName == "bookinglist"
                          ? "nav-link active show"
                          : "nav-link"
                      }
                      data-toggle="tab"
                      href="#list"
                      role="tab"
                      aria-controls="list"
                      onClick={event => this.changeNav(event, "bookinglist")}
                    >
                      {t("history")}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={
                        activeNavClassName == "payment"
                          ? "nav-link active show"
                          : "nav-link"
                      }
                      data-toggle="tab"
                      href="#payment"
                      role="tab"
                      aria-controls="messages"
                      onClick={this.getCardListDetails}
                    >
                      {t("payment")}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={
                        activeNavClassName == "chat"
                          ? "nav-link active show"
                          : "nav-link"
                      }
                      data-toggle="tab"
                      href="#chat"
                      role="tab"
                      aria-controls="chat-message"
                    >
                      {t("chat")}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={
                        activeNavClassName == "changepassword"
                          ? "nav-link active show"
                          : "nav-link"
                      }
                      data-toggle="tab"
                      href="#password"
                      role="tab"
                      aria-controls="password"
                      onClick={event => this.changeNav(event, "changepassword")}
                    >
                      {t("change_password")}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={
                        activeNavClassName == "deleteAccount"
                          ? "nav-link active show"
                          : "nav-link"
                      }
                      data-toggle="tab"
                      href="#delete"
                      role="tab"
                      aria-controls="account"
                      onClick={event => this.changeNav(event, "deleteAccount")}
                    >
                      {t("delete_account")}
                    </a>
                  </li>
                </ul>
                <div className="view-profile-btn-sec">
                    <Link to={"/profile"} className="btn btn-outline-primary mt-4">
                      {t("view_profile")}
                    </Link>
                  </div>
              </div>
              {loading ? (
                t("loading")
              ) : (
                <div className="col-xl-9 col-lg-9 col-md-12">
                  <div className="tab-content">
                    <div
                      className="tab-pane active"
                      id="edit-profile"
                      role="tabpanel"
                    >
                      <EditProfile data={data} />
                    </div>
                    <div className="tab-pane" id="photos" role="tabpanel">
                      <UploadPhoto data={data} />
                    </div>
                    <div className="tab-pane" id="list" role="tabpanel">
                      {loadingHistory ? (
                        t("loading")
                      ) : (
                        <ProfileHistorySection histories={histories} />
                      )}
                    </div>
                    <div className="tab-pane" id="payment" role="tabpanel">
                      <div className="row mb-5">
                        <div className="col-md-12">
                          <h4 className="card-title">Payment</h4>
                        </div>
                      </div>
                      {loadingCardList ? (
                        t("loading")
                      ) : (
                        <StripeProvider apiKey="pk_test_uDYrTXzzAuGRwDYtu7dkhaF3">
                          <Elements>
                            <Cards cardList={cardList} {...this.props} />
                          </Elements>
                        </StripeProvider>
                      )}
                    </div>
                    <div className="tab-pane" id="chat" role="tabpanel">
                    <div className="messaging">
                    <div className="inbox_msg">
                        <div className="inbox_people">
                            <div className="headind_srch">
                                <div className="recent_heading">
                                    <h4 className="card-title">{t("recent")}</h4>
                                </div>
                                <div className="srch_bar">
                                    <div className="stylish-input-group">
                                        <input type="text" className="search-bar" placeholder="Search"/>
                                        <span className="input-group-addon">
                                            <button type="button">
                                                <i className="fa fa-search" aria-hidden="true"></i> </button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="inbox_chat">
                                <div className="chat_list active_chat">
                                    <div className="chat_people">
                                        <div className="chat_img">
                                            <img src="/assests/img/placeholder-profile.jpg" alt="sunil" /> </div>
                                        <div className="chat_ib">
                                            <h5>Sunil Rajput <span className="chat_date">May 1st 2020</span></h5>
                                            <p>Test, which is a new approach to have all solutions
                                                astrology under one roof.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="chat_list">
                                    <div className="chat_people">
                                        <div className="chat_img">
                                            <img src="/assests/img/placeholder-profile.jpg" alt="sunil" /> </div>
                                        <div className="chat_ib">
                                            <h5>Sunil Rajput <span className="chat_date">May 1st 2020</span></h5>
                                            <p>Test, which is a new approach to have all solutions
                                                astrology under one roof.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="chat_list">
                                    <div className="chat_people">
                                        <div className="chat_img">
                                            <img src="/assests/img/placeholder-profile.jpg" alt="sunil" /> </div>
                                        <div className="chat_ib">
                                            <h5>Sunil Rajput <span className="chat_date">May 1st 2020</span></h5>
                                            <p>Test, which is a new approach to have all solutions
                                                astrology under one roof.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="chat_list">
                                    <div className="chat_people">
                                        <div className="chat_img">
                                            <img src="/assests/img/placeholder-profile.jpg" alt="sunil" /> </div>
                                        <div className="chat_ib">
                                            <h5>Sunil Rajput <span className="chat_date">May 1st 2020</span></h5>
                                            <p>Test, which is a new approach to have all solutions
                                                astrology under one roof.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="chat_list">
                                    <div className="chat_people">
                                        <div className="chat_img">
                                            <img src="/assests/img/placeholder-profile.jpg" alt="sunil" /> </div>
                                        <div className="chat_ib">
                                            <h5>Sunil Rajput <span className="chat_date">May 1st 2020</span></h5>
                                            <p>Test, which is a new approach to have all solutions
                                                astrology under one roof.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="chat_list">
                                    <div className="chat_people">
                                        <div className="chat_img">
                                            <img src="/assests/img/placeholder-profile.jpg" alt="sunil" /> </div>
                                        <div className="chat_ib">
                                            <h5>Sunil Rajput <span className="chat_date">May 1st 2020</span></h5>
                                            <p>Test, which is a new approach to have all solutions
                                                astrology under one roof.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="chat_list">
                                    <div className="chat_people">
                                        <div className="chat_img">
                                            <img src="/assests/img/placeholder-profile.jpg" alt="sunil" /> </div>
                                        <div className="chat_ib">
                                            <h5>Sunil Rajput <span className="chat_date">May 1st 2020</span></h5>
                                            <p>Test, which is a new approach to have all solutions
                                                astrology under one roof.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mesgs">
                            <div className="msg_history">
                                <div className="incoming_msg">
                                    <div className="incoming_msg_img">
                                        <img src="/assests/img/placeholder-profile.jpg" alt="sunil" /> </div>
                                    <div className="received_msg">
                                        <div className="received_withd_msg">
                                            <p>Test which is a new approach to have all
                                                solutions</p>
                                            <span className="time_date"> 11:01 AM | June 9</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="outgoing_msg">
                                    <div className="sent_msg">
                                        <p>Test which is a new approach to have all
                                            solutions</p>
                                        <span className="time_date"> 11:01 AM | June 9</span>
                                    </div>
                                </div>
                                <div className="incoming_msg">
                                    <div className="incoming_msg_img">
                                        <img src="/assests/img/placeholder-profile.jpg" alt="sunil" /> </div>
                                    <div className="received_msg">
                                        <div className="received_withd_msg">
                                            <p>Test, which is a new approach to have</p>
                                            <span className="time_date"> 11:01 AM | Yesterday</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="outgoing_msg">
                                    <div className="sent_msg">
                                        <p>Apollo University, Delhi, India Test</p>
                                        <span className="time_date"> 11:01 AM | Today</span>
                                    </div>
                                </div>
                                <div className="incoming_msg">
                                    <div className="incoming_msg_img">
                                        <img src="/assests/img/placeholder-profile.jpg" alt="sunil" /> </div>
                                    <div className="received_msg">
                                        <div className="received_withd_msg">
                                            <p>We work directly with our designers and suppliers,
                                                and sell direct to you, which means quality, exclusive
                                                products, at a price anyone can afford.</p>
                                            <span className="time_date"> 11:01 AM | Today</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="type_msg">
                                <div className="input_msg_write">
                                    <input type="text" className="write_msg" placeholder="Type a message" />
                                    <button className="msg_send_btn" type="button"><i class="fas fa-paper-plane"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                    </div>
                    <div className="tab-pane" id="password" role="tabpanel">
                      <ChangePassword />
                    </div>
                    <div className="tab-pane" id="delete" role="tabpanel">
                      <DeleteAccount />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default withToastManager(translate(UserProfile));
