import React, { Component } from "react";
import { Link } from "react-router-dom";

class SpaceTypeView extends Component {
  state = {};
  render() {
    const { spaceType } = this.props;
    return (
      <section className="sm-padding" id="cowork">
        <div className="container">
          <div className="row mb-5 row-margin-zero">
            <div className="col-md-12">
              <h2 className="title_main no-margin">
                Solution Designed For All Your Needs - {spaceType.title}
              </h2>
              <i className="border-title"></i>
            </div>
          </div>
          <div className="row">
            {spaceType.data.length > 0
              ? spaceType.data.map(spacetype => (
                  <div className="col-xl-3 col-lg-6 col-md-6 resp-mt respt-mt-lg">
                    <div className="workspace-card">
                      <div className="card_img">
                        <img src={spacetype.picture} alt="" />
                      </div>
                      <div className="card_details">
                        <h4 className="card-title">{spacetype.name}</h4>
                        <p className="card-desc no-margin">
                          {spacetype.description}
                        </p>
                        <Link
                          to={`/explore/${spacetype.type}`}
                          className="btn contact-link no-padding mr-2"
                        >
                          Explore This Space
                          <i className="fas fa-long-arrow-alt-right banner-hvr-icon"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                ))
              : "No space found"}
          </div>
        </div>
      </section>
    );
  }
}

export default SpaceTypeView;
