import React, { Component } from "react";
import api from "../../Environment";
import Helper from "../Helper/helper";

class ReviewSection extends Helper {
  state = {
    reviews: null,
    loading: true,
    skipCount: 0,
    loadingStatus: true,
    loadingContent: null,
    contentData: null
  };
  componentDidMount() {
    this.reviewsApiCall();
  }
  reviewsApiCall() {
    let items;
    api
      .postMethod("reviews", {
        space_id: this.props.details.space_id,
        skip: this.state.skipCount
      })
      .then(response => {
        if (response.data.success) {
          if (this.state.reviews != null) {
            items = [...this.state.reviews, ...response.data.data];
          } else {
            items = [...response.data.data];
          }
          this.setState({
            reviews: items,
            loading: false,
            skipCount: response.data.data.length + this.state.skipCount,
            loadingStatus: true
          });
        }
      });
  }
  loadMore = event => {
    event.preventDefault();
    this.setState({ loadingStatus: false, loadingContent: "Loading..." });

    this.reviewsApiCall();
  };
  render() {
    const { reviews, loading, loadingContent, loadingStatus } = this.state;
    return (
      <div className="col-md-12 mt-5">
        <h3 className="title-head mb-4">Reviews on office space listing</h3>
        {loading
          ? "Loading..."
          : reviews.length > 0
          ? reviews.map(review => (
              <div className="user-review mb-4">
                <div className="user-img">
                  <img src={review.user_picture} alt="user" />
                </div>
                <div className="review">
                  <h5>{review.user_name}</h5>
                  <div className="rating">
                    <span className="rating-star">
                      {this.starRatingHost(
                        review.ratings,
                        15
                      )}
                    </span><br></br>
                    
                    <span className="review-date">{review.updated}.</span>
                  </div>
                  {/* <h3 className="sub-head mt-1">Good budget workspacer</h3> */}
                  <p className="card-desc">{review.review}</p>
                </div>
              </div>
            ))
          : "No review found"}
        {/* {localStorage.getItem("userLoginStatus") ? (
          <div>
            <h3 className="title-head mb-3">Write a Review</h3>
            <div className="rating mb-4">
              <i className="fa fa-star active"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
            </div>
            <form className="review-form">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="John Deo"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Email</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="johndeo@gmail.com"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Review Title</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Write review"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Email</label>
                    <textarea className="form-control" rows="3"></textarea>
                  </div>
                </div>
              </div>
              <a className="btn btn-outline-primary mt-4" href="#">
                Submit review
              </a>
            </form>
          </div>
        ) : (
          ""
        )} */}
      </div>
    );
  }
}

export default ReviewSection;
