import React, { Component } from "react";
import api from "../../Environment";
import { Link } from "react-router-dom";

class SimilarSpace extends Component {
  state = {
    spaces: null,
    loading: true
  };
  componentDidMount() {
    this.suggesstionAPICall();
  }
  suggesstionAPICall = () => {
    api
      .postMethod("space_suggestions", { space_id: this.props.space_id })
      .then(response => {
        if (response.data.success) {
          this.setState({
            spaces: response.data.data,
            loading: false
          });
        } else {
        }
      });
  };
  render() {
    const { spaces, loading } = this.state;
    return (
      <section className="sm-padding">
        <div className="container">
          <div className="row mb-5 row-margin-zero">
            <div className="col-md-12">
              <h2 className="title_main no-margin">Similar Locations</h2>
              <i className="border-title"></i>
            </div>
          </div>
          <div className="row">
            {loading
              ? "Loading..."
              : spaces.length > 0
              ? spaces.map(space => (
                  <div className="col-xl-3 col-lg-3 col-md-6 mb-4" key={space.space_id}>
                    <div className="workspace-card">
                      <Link to={`/single-space/${space.space_id}`}>
                        <div className="card_img similar-location-img">
                          <img src={space.space_picture} alt="" />
                        </div>
                        <div className="card_details">
                          <h4 className="card-title">{space.space_name}</h4>
                          <p className="card-desc">{space.space_location}</p>
                          <p className="card-desc highlight-txt">
                            {space.space_type == "shared_space"
                              ? "Shared Space"
                              : ""}
                            {space.space_type == "private_space"
                              ? "Private Space"
                              : ""}
                            {space.space_type == "own_space" ? "Own Space" : ""}
                          </p>
                          <h5 className="price mt-2">Starting Price</h5>
                          <p className="card-desc">
                            <span>{space.per_day_symbol}</span>
                            <strong>
                              <span className="float-right">
                                {space.per_day_formatted}
                              </span>
                            </strong>
                          </p>
                        </div>
                      </Link>
                    </div>
                  </div>
                ))
              : "No Data Found"}
          </div>
        </div>
      </section>
    );
  }
}

export default SimilarSpace;
