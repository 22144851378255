import React, { Component } from "react";
import api from "../../Environment";
import { Link } from "react-router-dom";
import { translate, t } from "react-multi-lang";

class BookingUpcoming extends Component {
  state = {
    histories: null,
    upcoming: null,
    loadingHistory: true,
    loadingUpcoming: true
  };
  componentDidMount() {
    api.postMethod("bookings_upcoming").then(response => {
      if (response.data.success === true) {
        this.setState({ upcoming: response.data.data, loadingUpcoming: false });
      }
    });
  }
  render() {
    const { loadingHistory, loadingUpcoming, histories, upcoming } = this.state;
    return (
      <section className="sm-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              {loadingUpcoming
                ? t("loading")
                : upcoming.length > 0
                ? upcoming.map(history => (
                    <div className="user-booking-list" key={history.booking_id}>
                      <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-4">
                          <div className="user-img">
                            <img src={history.space_picture} alt="user" />
                          </div>
                          <div className="booking-details">
                            <h3 className="card-title">{history.space_name}</h3>
                            {/* <p className="card-desc">{history.}</p> */}
                          </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4 text-align-center">
                          <p className="card-desc">
                            {t("move_in_date")}<strong> : {history.checkin}</strong>
                          </p>
                          <p className="card-desc">
                            {t("number_of_seats")}
                            <strong> : {history.total_seats}</strong>
                          </p>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4 text-align-right mt-3">
                          <Link
                            className="btn btn-primary"
                            to={`single-booking-history/${history.booking_id}`}
                          >
                            {t("view")}
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))
                : t("no_data_found")}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default translate(BookingUpcoming);
