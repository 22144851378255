import React, { Component } from "react";
import SimilarSpace from "../Helper/similarSpace";
import AddSpaceLink from "../Helper/addSpaceLink";
import MapContainer from "../Helper/mapHelper";
import Helper from "../Helper/helper";
import { Link } from "react-router-dom";
import Map, { GoogleApiWrapper, Marker } from "google-maps-react";
import { withToastManager } from "react-toast-notifications";
import { apiConstants } from "../Constant/constants";
import ToastDemo from "../Helper/toaster";
import { translate, t } from "react-multi-lang";

class ViewAll extends Helper {
  state = {
    first_block: null,
    mainData: null,
    loading: true,
    skipCount: 0,
    loadingStatus: true,
    loadingContent: null,
    contentData: null,
    map: true,
    formData: {
      latitude: "",
      longitude: "",
      full_address: ""
    }
  };

  componentDidMount() {
    // Call api function
    let inputData;
    if (this.props.location.state) {
      inputData = {
        url_type: this.props.match.params.url_type,
        skip: this.state.skipCount,
        latitude: this.props.location.state.latitude,
        longitude: this.props.location.state.longitude
      };
    } else {
      inputData = {
        url_type: this.props.match.params.url_type,
        skip: this.state.skipCount
      };
    }
    console.log("CDM");
    this.seeAll(inputData);
  }

  // UNSAFE_componentWillReceiveProps(props) {
  //   let inputData;
  //   if (props.location.state) {
  //     inputData = {
  //       url_type: props.match.params.url_type,
  //       skip: this.state.skipCount,
  //       latitude: props.location.state.latitude,
  //       longitude: props.location.state.longitude
  //     };
  //   } else {
  //     inputData = {
  //       url_type: props.match.params.url_type,
  //       skip: this.state.skipCount
  //     };
  //   }
  //   console.log("CWR");
  //   setTimeout(() => {
  //     this.seeAll(inputData);
  //   }, 1000);
  // }

  displaySpaces = event => {
    event.preventDefault();
    if (!this.state.formData.full_address) {
      ToastDemo(this.props.toastManager, "Enter the location", "error");
      return false;
    }
    this.seeAll(this.state.formData);
  };

  renderAutoComplete = () => {
    console.log("asdfsf");
    const { google } = this.props;
    if (!google) {
      console.log("asdfsadfasdfno");
      return;
    }

    const autocomplete = new google.maps.places.Autocomplete(
      this.autocomplete,
      { types: ["geocode"] }
    );

    autocomplete.setFields(["address_component", "geometry", "name"]);

    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      console.log("Place", place.geometry);
      console.log("asdf", autocomplete.getPlace());
      if (!place.geometry) {
        console.log("sdfsd efe");
        return;
      }
      // this.setState({ position: place.geometry.location });
      const formData = { ...this.state.formData };
      formData["latitude"] = place.geometry.location.lat();
      formData["longitude"] = place.geometry.location.lng();
      let full_address = "";
      place.address_components.map(
        address =>
          (full_address =
            full_address == ""
              ? address.long_name
              : full_address + "," + address.long_name)
      );
      formData["full_address"] = full_address;
      formData["url_type"] = 3;
      this.setState({ formData });
      setTimeout(() => {
        this.seeAll(this.state.formData);
      }, 1000);
    });
  };

  spaceType = value => {
    // this.setState({ priceTypeText: value });
    const formData = { ...this.state.formData };
    formData["space_type"] = value;
    //   formData["latitude"] = place.geometry.location.lat();
    //   formData["longitude"] = place.geometry.location.lng();
      console.log(formData);
    setTimeout(() => {
      this.seeAll(formData);
    }, 1000);
  };

  loadMore = event => {
    event.preventDefault();
    this.setState({ loadingStatus: false, loadingContent: "Loading..." });
    const inputData = {
      url_type: this.props.match.params.url_type,
      skip: this.state.skipCount
    };

    this.seeAll(inputData, "loadMore");
  };
  render() {
    const { loading, loadingStatus, loadingContent, contentData } = this.state;
    return (
      <div>
        <section className="sm-padding">
          <div className="container">
            {/* <div className="row">
              <div className="col-md-12">
                <div className="go-search mb-5">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={t("search_location")}
                    />
                    <div className="input-group-append">
                      <a className="btn btn-outline-light">
                        <i className="fas fa-map-marker-alt mr-2"></i> {t("locate_me")}
                      </a>
                      <a className="btn btn-search hvr-bounce-to-left">
                        {t("search")}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="row mb-4">
              <div className="col-md-12">
                <h2 className="title_main no-margin">{t("bengaluru")}</h2>
                <i className="border-title"></i>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-md-7">
                <div className="dropdown move-in">
                  <button
                    type="button"
                    className="btn btn-outline-primary dropdown-toggle"
                    data-toggle="dropdown"
                  >
                    {t("space_type")}
                  </button>
                  <div className="dropdown-menu locate">
                    <div className="month-select">
                      <h6 className="mb-3">
                        <strong>{t("space_type")}</strong>
                      </h6>
                      <div className="custom-controls-stacked">
                        <div className="custom-control custom-radio mb-3">
                          <input
                            type="radio"
                            id="customRadio1"
                            name="customRadio"
                            className="custom-control-input"
                            onClick={() => this.spaceType("shared_space")}
                          />
                          <label
                            className="custom-control-label"
                            for="customRadio1"
                          >
                            {t("shared_space")}
                          </label>
                        </div>
                      </div>
                      <div className="custom-controls-stacked">
                        <div className="custom-control custom-radio mb-3">
                          <input
                            type="radio"
                            id="customRadio2"
                            name="customRadio"
                            className="custom-control-input"
                            onClick={() => this.spaceType("private_space")}
                          />
                          <label
                            className="custom-control-label"
                            for="customRadio2"
                          >
                            {t("private_space")}
                          </label>
                        </div>
                      </div>
                      <div className="custom-controls-stacked">
                        <div className="custom-control custom-radio mb-3">
                          <input
                            type="radio"
                            id="customRadio3"
                            name="customRadio"
                            className="custom-control-input"
                            onClick={() => this.spaceType("own_space")}
                          />
                          <label
                            className="custom-control-label"
                            for="customRadio3"
                          >
                            {t("own_space")}
                          </label>
                        </div>
                      </div>
                      <div className="custom-controls-stacked">
                        <div className="custom-control custom-radio mb-3">
                          <input
                            type="radio"
                            id="customRadio4"
                            name="customRadio"
                            className="custom-control-input"
                            onClick={() => this.spaceType("office_suite")}
                          />
                          <label
                            className="custom-control-label"
                            for="customRadio4"
                          >
                            {t("office_suite")}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="dropdown-footer mt-4">
                      <span className="cancel">
                        <a href='#' onClick={() => this.seeAll(this.state.formData)}>{t("cancel")}</a>
                      </span>
                      {/* <span className="apply float-right">
                        <a href="#">{t("apply")}</a>
                      </span> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-5 text-right">
                {/*<h6 className="view-location">
                  Viewing cowork building in map
                </h6> */}
                <form onSubmit={this.displaySpaces}>
                  <div className="location_select">
                    <div className="input-group mb-4">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="fas fa-map-marker-alt"></i>
                        </span>
                      </div>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Search"
                        onFocus={this.renderAutoComplete}
                        ref={ref => (this.autocomplete = ref)}
                      ></input>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="row">
              <div className="col-md-7">
                <div className="row">
                  {loading
                    ? t("loading")
                    : contentData.length > 0
                    ? contentData.map(list => (
                        <div className="col-md-6 mb-5" key={list.space_id}>
                          <div className="workspace-card">
                            <Link to={`/single-space/${list.space_id}`}>
                              <div className="card_img">
                                <img src={list.space_picture} alt="" />
                              </div>
                              <div className="card_details">
                                <h4 className="card-title">
                                  {list.space_name}
                                </h4>
                                <p className="card-desc">
                                  {list.space_location}
                                </p>
                                <p className="card-desc highlight-txt">
                                  {list.space_type == "shared_space"
                                    ? "Shared Space"
                                    : ""}
                                  {list.space_type == "private_space"
                                    ? "Private Space"
                                    : ""}
                                  {list.space_type == "own_space"
                                    ? "Own Space"
                                    : ""}
                                    {list.space_type == "office_suite"
                                    ? "Office Suite"
                                    : ""}
                                </p>
                                <h5 className="price mt-3">Starting Price</h5>
                                <p className="card-desc">
                                  <span>{list.per_day_symbol}</span>
                                  <strong>
                                    <span className="float-right">
                                      {list.per_day_formatted}
                                    </span>
                                  </strong>
                                </p>
                                {/* <p className="card-desc no-margin">
                                  Per Month{" "}
                                  <strong>
                                    <span className="float-right">$ 1500</span>
                                  </strong>
                                </p> */}
                              </div>
                            </Link>
                          </div>
                        </div>
                      ))
                    : t("no_data_found")}
                </div>
              </div>
              <div className="col-md-5 resp-mt">
                <div
                  className="map-position map"
                  style={{ width: "100%;", height: "500px" }}
                >
                  {this.state.contentData &&
                  this.state.contentData.length > 0 ? (
                    <MapContainer data={this.state.contentData} />
                  ) : (
                    false
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        <SimilarSpace />
        <AddSpaceLink />
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: apiConstants.google_api_key
})(withToastManager(translate(ViewAll)));
