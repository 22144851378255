import React, { Component } from "react";
import { translate, t } from "react-multi-lang";

const $ = window.$;
class Footer extends Component {
  state = {};
  componentDidMount() {
    // this.homePageApiCall();
    var footerHeight = $("#footer").outerHeight();
        var deviceheight = $(window).outerHeight();
        var contentheight = deviceheight - footerHeight;
        $(".main-sec-content").css("min-height", contentheight);
  }
  render() {
    return (
      <div id="footer">
        <footer className="footer sm-padding bg-color1">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <div className="footer_logo mb-2">
                  <img
                    className="main_logo"
                    src={
                      window.location.origin + "/assests/img/footer_logo.png"
                    }
                  />
                </div>
                <p className="footer_desc">
                  {t("footer_para")}{" "}
                </p>
                <p className="footer_desc margin-top-xl">
                  <a href="#">
                    <i className="fa fa-phone mr-2"></i>1800 123 456 789
                  </a>
                </p>
                <p className="footer_desc mt-2">
                  <i className="far fa-building mr-2"></i>{t("footer_address")}
                </p>
              </div>
              <div className="col-md-4 footer_list pl-5">
                <div className="footer_title">{t("types_of_spaces")}</div>
                <ul className="no-padding">
                  <li>
                    <a href="#">{t("coworking_space")}</a>
                  </li>
                  <li>
                    <a href="#">{t("meeting_space")}</a>
                  </li>
                  <li>
                    <a href="#">{t("office_space")}</a>
                  </li>
                  <li>
                    <a href="#">{t("retail_space")}</a>
                  </li>
                  <li>
                    <a href="#">{t("event_space")}</a>
                  </li>
                  <li>
                    <a href="#">{t("virtual_space")}</a>
                  </li>
                </ul>
              </div>
              <div className="col-md-4 footer_list">
                <div className="footer_title">Company</div>
                <ul className="no-padding">
                  <li>
                    <a href="#">{t("about_us")}</a>
                  </li>
                  <li>
                    <a href="#">{t("careers")}</a>
                  </li>
                  <li>
                    <a href="#">{t("clients")}</a>
                  </li>
                  <li>
                    <a href="#">{t("team")}</a>
                  </li>
                  <li>
                    <a href="#">{t("help_center")}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </footer>
        <div className="sub_footer bg-color2">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <ul className="social__footer no-margin">
                  <li>
                    <a href="">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a href="">
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="">
                      <i className="fab fa-youtube"></i>
                    </a>
                  </li>
                  <li>
                    <a href="">
                      <i className="fab fa-instagram"></i>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-md-6 tex-right">
                <p className="copyright text-right">
                  {t("footer_copyright_para")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default translate(Footer);
