import React, { Component } from "react";
import { withToastManager } from "react-toast-notifications";
import ToastDemo from "../Helper/toaster";
import AddSpaceLink from "../Helper/addSpaceLink";
import api from "../../Environment";
import HowItWorks from "./howItWorks";
import StaticContent from "./staticContent";
import SpaceTypeView from "./spaceTypeView";
import SpaceListView from "./spaceListView";
import { translate, t } from "react-multi-lang";

class ExplorePage extends Component {
  state = {
    loading: true,
    response: null
  };
  componentDidMount() {
    this.homePageApiCall(this.props.match.params.type);
  }

  //WARNING! To be deprecated in React v17. Use new lifecycle static getDerivedStateFromProps instead.
  componentWillReceiveProps(nextProps) {
    console.log(nextProps);
    this.setState({ loading: true });
    this.homePageApiCall(nextProps.match.params.type);
  }

  homePageApiCall = (parameter, type) => {
    api.postMethod("spaces_home", { space_type: this.props.match.params.type }).then(response => {
      if (response.data.success) {
        this.setState({
          loading: false,
          response: response.data.data
        });
      } else {
      }
    });
  };

  render() {
    const { loading, response } = this.state;
    return (
      <div>
        {loading
          ? t("loading")
          : response.map((data, index) => (
              <React.Fragment>
                {index == 0 ? (
                  <React.Fragment>
                    <SpaceTypeView
                      spaceType={response[index]}
                      parameter={data.key}
                    />

                    <StaticContent />
                  </React.Fragment>
                ) : (
                  ""
                )}
                {index == 1 ? (
                  response[index].data.length > 0 ? (
                    <SpaceListView spaceList={response[index]} />
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}

                {index == 2 ? (
                  response[index].data.length > 0 ? (
                    <SpaceListView spaceList={response[index]} />
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </React.Fragment>
            ))}
        <HowItWorks />
        <AddSpaceLink />
      </div>
    );
  }
}

export default withToastManager(translate(ExplorePage));
