import React, { Component } from "react";
import api from "../../Environment";
import { withToastManager } from "react-toast-notifications";
import ToastDemo from "../Helper/toaster";
import { translate, t } from "react-multi-lang";

class DeleteAccount extends Component {
  state = {
    deleteAccountInput: {},
    loadingDeleteAccContent: null,
    buttonDisableDeleteAcc: false
  };

  getDeleteAccountInput = ({ currentTarget: input }) => {
    const deleteAccountInput = { ...this.state.deleteAccountInput };
    deleteAccountInput[input.name] = input.value;
    this.setState({ deleteAccountInput });
  };

  handleDelete = event => {
    event.preventDefault();
    this.setState({
      loadingDeleteAccContent: "Loading... Please wait..",
      buttonDisableDeleteAcc: true
    });
    api
      .postMethod("delete_account", this.state.deleteAccountInput)
      .then(response => {
        if (response.data.success === true) {
          ToastDemo(this.props.toastManager, response.data.message, "success");

          this.setState({
            loadingDeleteAccContent: null,
            buttonDisableDeleteAcc: false
          });
          localStorage.removeItem("accessToken");
          localStorage.removeItem("userId");
          localStorage.removeItem("userLoginStatus");
          localStorage.removeItem("user_picture");
          localStorage.removeItem("username");
          window.location = "/";
        } else {
          ToastDemo(this.props.toastManager, response.data.error, "error");
          this.setState({
            loadingDeleteAccContent: null,
            buttonDisableDeleteAcc: false
          });
        }
      });
  };

  render() {
    return (
      <div className="row">
        <div className="col-xl-8 col-lg-8 col-md-12">
          <div className="contact">
            <h4 className="card-title mb-4">{t("sorry_to_see_you_go")}</h4>
            <form onSubmit={this.handleDelete}>
              <div className="form-group">
                <label>{t("old_password")}</label>
                <input
                  type="password"
                  className="form-control mb-1"
                  placeholder={t("old_password_placeholder")}
                  name="password"
                  value={this.state.deleteAccountInput.password}
                  onChange={this.getDeleteAccountInput}
                />
                <span className="del-text">
                  {t("account_delete_para")}
                </span>
              </div>
              <div className="row">
                <div className="col-md-12 mt-3">
                  <button
                    type="submit"
                    className="btn btn-primary btn-lg btn-block"
                    disabled={this.state.buttonDisableDeleteAcc}
                  >
                    {this.state.loadingDeleteAccContent != null
                      ? this.state.loadingDeleteAccContent
                      : t("submit")}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default withToastManager(translate(DeleteAccount));
