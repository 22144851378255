import React, { Component } from "react";
import Helper from "../Helper/helper";
import ToastDemo from "../Helper/toaster";
import api from "../../Environment";
import { withToastManager } from "react-toast-notifications";
import { translate, t } from "react-multi-lang";
const $ = window.$;

class Login extends Helper {
  state = {
    data: {
      email: "",
      password: "",
      device_type: "web"
    },
    loadingContent: null,
    buttonDisable: false,
    forgotModel: "none",
    loginModel: "block"
  };
  showForgotPassword = event => {
    event.preventDefault();
    this.setState({ forgotModel: "block", loginModel: "none" });
  };
  showLoginModel = event => {
    event.preventDefault();
    this.setState({ forgotModel: "none", loginModel: "block" });
  };
  handleSubmit = event => {
    event.preventDefault();
    const path = this.props.location;
    this.setState({
      loadingContent: "Loading... Please wait..",
      buttonDisable: true
    });
    api
      .postMethod("login", this.state.data)
      .then(response => {
        if (response.data.success === true) {
          localStorage.setItem("userId", response.data.data.user_id);
          localStorage.setItem("accessToken", response.data.data.token);
          localStorage.setItem("userLoginStatus", true);
          localStorage.setItem("user_picture", response.data.data.picture);
          localStorage.setItem("username", response.data.data.username);

          // window.location = path ? path.from.pathname : "/home";
          ToastDemo(this.props.toastManager, response.data.message, "success");
          this.setState({ loadingContent: null, buttonDisable: false });
          $("#login-model").modal("hide");
          if (path) {
            this.props.history.push(path.pathname);
          } else {
            this.props.history.push("/");
          }
          // window.locatiom = path
          //   ? this.props.history.push(path.from.pathname)
          //   : this.props.history.push("/home");
          // this.props.history.push("/home");
        } else {
          this.setState({ loadingContent: null, buttonDisable: false });
          ToastDemo(this.props.toastManager, response.data.error, "error");
        }
      })
      .catch(error => {
        this.setState({ loadingContent: null, buttonDisable: false });
      });
  };
  render() {
    const { data, forgotModel, loginModel } = this.state;
    return (
      <div
        className="modal fade bd-login-modal-lg"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
        id="login-model"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="back-btn"
                style={{ display: forgotModel }}
                onClick={this.showLoginModel}
              >
                <i className="fas fa-long-arrow-alt-left mr-2"></i>{t("back_to_login")}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12 mb-4">
                  <h5 className="modal-title text-center">{t("welcome")}!</h5>
                  <i className="border-title mx-auto mb-3"></i>
                </div>
              </div>
              <div
                className="row forgot-modal"
                style={{ display: forgotModel }}
              >
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-6 pr-5 mt-5">
                      <div className="forgot-img">
                        <img
                          src={
                            window.location.origin + "/assests/img/forgot.svg"
                          }
                          alt="User Login"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <h3 className="title-head mb-4">{t("forget_password")}</h3>
                      <form>
                        <div className="form-group">
                          <label className="col-form-label">{t("email")}</label>
                          <input
                            type="email"
                            className="form-control"
                            placeholder={t("email_address_placeholder")}
                            name="email"
                            value={data.email}
                            onChange={this.handleChange}
                          />
                        </div>
                      </form>
                      <a
                        href="#"
                        className="btn btn-primary btn-block mt-4 forgot-to-login"
                      >
                        {t("send")}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="row login-modal"
                style={{ display: loginModel == "block" ? "" : loginModel }}
              >
                <div className="col-md-6 pr-5">
                  <div className="login-img">
                    <img
                      src={window.location.origin + "/assests/img/log.svg"}
                      alt="User Login"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <form onSubmit={this.handleSubmit}>
                    <div className="form-group">
                      <label className="col-form-label">{t("email_address")}</label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder={t("email_address_placeholder")}
                        name="email"
                        value={data.email}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label className="col-form-label">{t("password")}</label>
                      <input
                        type="password"
                        className="form-control"
                        placeholder={t("password_placeholder")}
                        name="password"
                        value={data.password}
                        onChange={this.handleChange}
                      />
                    </div>

                    <a
                      href="#"
                      className="forgot"
                      onClick={this.showForgotPassword}
                    >
                      {t("forgotten_password")}
                    </a>
                    <button
                      type="submit"
                      className="btn btn-primary btn-block mt-4"
                      disabled={this.state.buttonDisable}
                    >
                      {this.state.loadingContent != null
                        ? this.state.loadingContent
                        : t("login")}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withToastManager(translate(Login));
