import React, { Component } from "react";
import Helper from "../Helper/helper";
import api from "../../Environment";
import { withToastManager } from "react-toast-notifications";
import ToastDemo from "../Helper/toaster";
import { translate, t } from "react-multi-lang";

class UploadPhoto extends Helper {
  state = {
    changeImageInput: {},
    loadingChangeImageContent: null,
    buttonDisableChangeImage: false,
    imagePreviewUrl: null
  };

  componentDidMount() {
    this.getUserDetails();
  }

  changeImage = ({ currentTarget: input }) => {
    const changeImageInput = { ...this.state.changeImageInput };
    if (input.type === "file") {
      changeImageInput[input.name] = input.files[0];
      this.setState({ changeImageInput });
    }
    let reader = new FileReader();
    let file = input.files[0];

    reader.onloadend = () => {
      this.setState({
        imagePreviewUrl: reader.result
      });
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  handleUploadImage = event => {
    event.preventDefault();
    this.setState({
      loadingChangeImageContent: "Loading... Please wait..",
      buttonDisableChangeImage: true
    });

    const inputData = { ...this.state.changeImageInput };
    inputData["name"] = this.state.data.username;
    inputData["email"] = this.state.data.email;

    this.setState({ inputData });

    api
      .postMethod("update_profile", inputData)
      .then(response => {
        if (response.data.success) {
          ToastDemo(this.props.toastManager, response.data.message, "success");
          this.setState({
            loadingChangeImageContent: null,
            buttonDisableChangeImage: false
          });
          localStorage.setItem("user_picture", response.data.data.picture);
        } else {
          ToastDemo(this.props.toastManager, response.data.error, "error");
          this.setState({
            loadingChangeImageContent: null,
            buttonDisableChangeImage: false
          });
        }
      });
  };

  render() {
    const { imagePreviewUrl } = this.state;
    const { data } = this.props;
    const profileImage =
      imagePreviewUrl != null ? imagePreviewUrl : data.picture;
    return (
      <div className="row prev_outer">
        <div className="col-md-4">
          <div className="prev-img-blk-outer">
            <div className="prev-img-blk">
              <div
                className="img_outer"
                id="prev_image_preview"
                style={{
                  backgroundImage: "url(" + profileImage + ")"
                }}
              ></div>
              <div className="fileUpload up-btn prev-up-btn">
                <input
                  type="file"
                  name="picture"
                  id="prev_img_upload_btn"
                  className="upload"
                  accept="image/x-png, image/jpeg"
                  onChange={this.changeImage}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-8">
          <div className="row">
            <div className="col-md-12 form-box">
              <h4 className="mb-2 card-title">{t("profile_photo")}</h4>
              <p className="card-desc">
                {t("profile_photo_para1")}
              </p>
              <p className="card-desc">
                {t("profile_photo_para2")}
              </p>
            </div>
            <div className="col-md-12 mt-5">
              <button
                type="submit"
                className="btn btn-primary btn-block"
                disabled={this.state.buttonDisableChangeImage}
                onClick={this.handleUploadImage}
              >
                {this.state.loadingChangeImageContent != null
                  ? this.state.loadingChangeImageContent
                  : t("submit")}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withToastManager(translate(UploadPhoto));
