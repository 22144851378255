import React, { Component } from "react";

import Footer from "./Footers/footer";
import HomeHeader from "./Headers/homeHeader";

// This Layouts used in Home page Header,  floating footer and content

class FirstLayout extends Component {
  constructor(props) {
    super(props);
    this.eventEmitter = this.props.screenProps;
  }
  componentDidUpdate() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="wrapper">
        <HomeHeader {...this.props.children.props} />
        <div className="main-sec-content">
          {React.cloneElement(this.props.children, {
            eventEmitter: this.eventEmitter
          })}
        </div>
        <Footer />
      </div>
    );
  }
}
export default FirstLayout;
