import React, { Component } from "react";
import { withToastManager } from "react-toast-notifications";
import api from "../../Environment";
import ToastDemo from "../Helper/toaster";
import SingleSpaceMapView from "../Home/singleSpaceMapView";
import renderHTML from "react-render-html";
import { Link } from "react-router-dom";
import { translate, t } from "react-multi-lang";
import UserReviewModel from "./userReviewModel";
import Helper from "../Helper/helper";
import SingleSpaceImageSlider from "../Home/singleSpaceImageSlider";

class SingleBookingHistory extends Helper {
  state = {
    booking_details: null,
    loading: true,
    checkinBtnStatus: 0,
    checkoutBtnStatus: 0,
    cancelBtnStatus: 0,
    reviewBtnStatus: 0,
    messageBtnStatus: 0,
    CheckinApiCalled: false,
    CheckoutApiCalled: false,
    reviewApiCalled: false,
    cancelApiCalled: false
  };

  componentDidMount() {
    this.getSingleHistoryDetailsApiCall();
  }

  getSingleHistoryDetailsApiCall = () => {
    api
      .postMethod("bookings_view", { booking_id: this.props.match.params.id })
      .then(response => {
        if (response.data.success) {
          this.setState({
            booking_details: response.data.data,
            loading: false
          });
        }
        else{
        ToastDemo(this.props.toastManager, response.data.error, "error");
        this.props.history.push("/booking-history");
        }
      });
  };

  bookingCheckin = event => {
    event.preventDefault();
    api
      .postMethod("bookings_checkin", {
        booking_id: this.props.match.params.id
      })
      .then(response => {
        if (response.data.success) {
          this.getSingleHistoryDetailsApiCall();
          ToastDemo(this.props.toastManager, response.data.message, "success");
        } else {
          ToastDemo(this.props.toastManager, response.data.error, "error");
        }
      });
  };

  bookingCheckout = event => {
    event.preventDefault();
    api
      .postMethod("bookings_checkout", {
        booking_id: this.props.match.params.id
      })
      .then(response => {
        if (response.data.success) {
          this.getSingleHistoryDetailsApiCall();
          ToastDemo(this.props.toastManager, response.data.message, "success");
        } else {
          ToastDemo(this.props.toastManager, response.data.error, "error");
        }
      });
  };
  bookingCancel = event => {
    event.preventDefault();
    api
      .postMethod("spaces_bookings_cancel", {
        booking_id: this.props.match.params.id
      })
      .then(response => {
        if (response.data.success) {
          this.getSingleHistoryDetailsApiCall();
          ToastDemo(this.props.toastManager, response.data.message, "success");
        } else {
          ToastDemo(this.props.toastManager, response.data.error, "error");
        }
      });
  };

  bookingReview = event => {
    event.preventDefault();
    api
      .postMethod("bookings_rating_report", {
        booking_id: this.props.match.params.id
      })
      .then(response => {
        if (response.data.success) {
          this.getSingleHistoryDetailsApiCall();
          ToastDemo(this.props.toastManager, response.data.message, "success");
        } else {
          ToastDemo(this.props.toastManager, response.data.error, "error");
        }
      });
  };

  render() {
    const { booking_details, loading } = this.state;
    return (
      <section className="sm-padding">
        {loading ? (
          "Loading..."
        ) : (
          <div className="container">
            <div className="row mb-5">
              <div className="col-md-12">
                <h2 className="title_main no-margin">
                  {booking_details.space_name}
                </h2>
                <i className="border-title"></i>
              </div>
            </div>
            <div className="row">
              <div className="col-md-7">
                <div className="row">
                  <SingleSpaceImageSlider gallery={booking_details.gallery} />
                  <div className="col-md-12 mt-4">
                    <h3 className="card-title">
                      {t("finding_office_space_at")} {booking_details.space_location}
                    </h3>
                    <p className="card-desc">
                      {renderHTML(booking_details.space_description)}
                    </p>
                  </div>
                  <div className="col-md-12 mt-5">
                    <h2 className="title_main no-margin">{t("location")}</h2>
                    <i className="border-title mb-3"></i>
                    <p className="address mb-4">
                      {booking_details.full_address}
                    </p>
                    <div
                      className="map-position map"
                      style={{ width: "100%", height: "500px" }}
                    >
                      <SingleSpaceMapView
                        lat={booking_details.latitude}
                        long={booking_details.longitude}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <div className="contact">
                  <div className="row">
                    <div className="col-md-12">
                    <Link to={`/provider-profile/${booking_details.provider_details.provider_id}`}>
                      <div className="user-img mt-2">
                        <img src={booking_details.provider_details.picture} />
                      </div>
                    </Link>
                      <div className="booking-details">
                        <h4 className="sub-head">
                          {booking_details.provider_details.provider_name}
                        </h4>
                        <p className="sub-desc">{booking_details.provider_details.email}</p>
                      
                        <p className="sub-desc">
                          {t("move_in_date")} :{" "}
                          <strong className="txt-right">
                            {booking_details.checkin}
                          </strong>
                        </p>
                        <p className="sub-desc">
                          {t("check_out_date")} :{" "}
                          <strong className="txt-right">
                            {booking_details.checkout}
                          </strong>
                        </p>
                        <p className="sub-desc mt-2">
                          {t("no_of_seats")} :{" "}
                          <strong className="txt-right">
                            {booking_details.total_seats}
                          </strong>
                        </p>
                        <p className="sub-desc mt-2">
                          No Of{" "}
                          {booking_details.price_type == "per_day"
                            ? "Days"
                            : "Months"}{" "}
                          :{" "}
                          <strong className="txt-right">
                            {booking_details.price_type_text}
                          </strong>
                        </p>
                        <p className="sub-desc txt-overflow mt-2">
                          {t("payment_id")} :{" "}
                          <strong className="txt-right ">
                            #{booking_details.pricing_details.payment_id}
                          </strong>
                        </p>
                        <p className="sub-desc mt-2">
                          {t("payment_mode")} :{" "}
                          <strong className="txt-right">Card</strong>
                        </p>
                        <p className="sub-desc mt-2">
                          {t("sub_total")} :{" "}
                          <strong className="txt-right">
                            {
                              booking_details.pricing_details
                                .sub_total_formatted
                            }
                          </strong>
                        </p>
                        <p className="sub-desc mt-2">
                          {t("tax_price")} :{" "}
                          <strong className="txt-right">
                            {
                              booking_details.pricing_details
                                .tax_price_formatted
                            }
                          </strong>
                        </p>
                        <p className="sub-desc mt-2">
                          {t("total_amount_paid")}:{" "}
                          <strong className="txt-right">
                            {
                              booking_details.pricing_details
                                .paid_amount_formatted
                            }
                          </strong>
                        </p>
                        <p className="sub-desc mt-2">
                          <strong> {t("note")}:</strong> {booking_details.status_text}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-12 mt-3">
                      {booking_details.buttons.cancel_btn_status == 1 ? (
                        <a
                          href="#"
                          className="btn btn-primary btn-lg btn-block"
                          onClick={event => {
                            if (
                              window.confirm("Are you sure you want to cancel?")
                            )
                              this.bookingCancel(event);
                          }}
                        >
                          {t("cancel")}
                        </a>
                      ) : (
                        ""
                      )}
                      {booking_details.buttons.checkin_btn_status == 1 ? (
                        <a
                          href="#"
                          className="btn btn-primary btn-lg btn-block"
                          onClick={event => {
                            if (
                              window.confirm(
                                t("check_in_confirmation")
                              )
                            )
                              this.bookingCheckin(event);
                          }}
                        >
                          {t("check_in")}
                        </a>
                      ) : (
                        ""
                      )}
                      {booking_details.buttons.checkout_btn_status == 1 ? (
                        <a
                          href="#"
                          className="btn btn-primary btn-lg btn-block"
                          onClick={event => {
                            if (
                              window.confirm(
                                t("check_out_confirmation")
                              )
                            )
                              this.bookingCheckout(event);
                          }}
                        >
                          {t("check_out")}
                        </a>
                      ) : (
                        ""
                      )}
                      {booking_details.buttons.review_btn_status == 1 ? (
                        <a
                          className="btn btn-primary btn-lg btn-block"
                          href="#"
                          data-toggle="modal"
                          data-target="#userReviewModel"
                        >
                          {t("review")}
                        </a>
                      ) : (
                        ""
                      )}
                    </div>
                    
                  </div>
                </div>
              </div>
              <UserReviewModel booking_id={this.props.match.params.id} />
              
              <div className="col-md-12 ">
                {booking_details.reviews.ratings
                  ? 
                    <div className=" mb-4 mt-2">
                      <div className="gallery-content pt-4">
                        <h3 className="title">Review:</h3>
                        <p className="desc">
                          <div className="rating mb-2">
                            <span className="rating-star">
                              {this.starRatingHost(
                                booking_details.reviews.ratings,
                                25
                              )}
                            </span>
                          </div>
                        </p>
                        <p className="desc">{booking_details.reviews.review}</p>
                      </div>
                    </div>
                    
                  : ""}
              </div>
            </div>
          </div>
        )}
      </section>
    );
  }
}

export default withToastManager(translate(SingleBookingHistory));
