import React, { Component } from "react";
import ReactTimeout from "react-timeout";

import { Router, Switch, Route, Redirect } from "react-router-dom";

// import createHistory from "history/createBrowserHistory";

import { createBrowserHistory as createHistory } from "history";

import { ToastProvider } from "react-toast-notifications";

import { Elements, StripeProvider } from "react-stripe-elements";

import configuration from "react-global-configuration";
import { Helmet } from "react-helmet";
import { apiConstants } from "./components/Constant/constants";

// import files.

import FirstLayout from "./components/Layouts/firstLayout";
import LandingPage from "./components/Home/landingPage";
import UserProfile from "./components/UserAccounts/userProfile";
import Profile from "./components/UserAccounts/profile";
import ViewAll from "./components/Home/viewAll";
import SingleSpace from "./components/Home/singleSpace";
import BookingHistory from "./components/UserAccounts/bookingHistory";
import BookingUpcoming from "./components/UserAccounts/bookingUpcoming";
import SingleBookingHistory from "./components/UserAccounts/singleBookingHistory";
import Logout from "./components/Auth/logout";
import ExplorePage from "./components/Home/explorePage";
import ProviderProfile from "./components/UserAccounts/providerProfile";

import Checkout from "./components/Home/checkOut";

import {
  setTranslations,
  setDefaultLanguage,
  translate,
  setLanguage,
} from "react-multi-lang";
import en from "./components/translation/en.json";
import pt from "./components/translation/pt.json";
setTranslations({ pt, en });

const history = createHistory();

const $ = window.$;
const AppRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => (
      <Layout screenProps={ScreenProps}>
        <Component {...props} />
      </Layout>
    )}
    isAuthed
  />
);
const HostPrivateRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  host_authentication,
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      host_authentication === true ? (
        <Layout screenProps={ScreenProps}>
          <Component {...props} authRoute={true} />
        </Layout>
      ) : (
        <Redirect
          to={{
            pathname: "/host/login",
            state: { from: props.location }
          }}
        />
      )
    }
  />
);

const UserPrivateRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  user_authentication,
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      user_authentication === true ? (
        <Layout screenProps={ScreenProps}>
          <Component {...props} authRoute={true} />
        </Layout>
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
);
class App extends Component {
  constructor(props) {
    super(props);

    // user_Authentication

    let userId = localStorage.getItem("userId");

    let providerId = localStorage.getItem("hostId");

    let accessToken = localStorage.getItem("accessToken");

    this.state = {
      loading: true,
      user_authentication: userId && accessToken ? true : false,
      host_authentication: providerId && accessToken ? true : false
    };

    // this.eventEmitter = new Emitter();

    // configuration.set({
    //   configData: configData.data
    // });

    history.listen((location, action) => {
      userId = localStorage.getItem("userId");
      providerId = localStorage.getItem("hostId");
      accessToken = localStorage.getItem("accessToken");

      // this.setState({ loading : true, user_authentication : userId && accessToken ? true : false});

      this.setState({ loading: true, user_authentication: true });
      this.setState({ loading: true, host_authentication: true });

      // this.loadingFn();

      document.body.scrollTop = 0;
    });

    this.fetchConfig();
  }
  async fetchConfig() {
    const response = await fetch(apiConstants.settingsUrl);
    console.log("respi", response);
    const configValue = await response.json();
    configuration.set({
      configData: configValue.data
    });
    this.setState({ configLoading: false });
  }

  loadingFn() {
    this.props.setTimeout(() => {
      this.setState({ loading: false });
    }, 3 * 1000);
  }

  loadConfigData() {}

  componentDidMount() {
    this.loadConfigData(); // Not used

    let userLanguage = localStorage.getItem("lang")
        ? localStorage.getItem("lang")
        : "en";
        console.log(userLanguage);
        localStorage.setItem("lang", userLanguage);
        setLanguage(userLanguage);
  }
  componentWillMount() {
    // document.title = configData.data.site_name;
  }

  render() {
    /*const isLoading = this.state.loading;
      
      if (isLoading) {

        return (
          <div className="wrapper">
            <div className="loader-warpper">
                <div id="loader">
                  <ul>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                  </ul>
                </div>
            </div>
          </div>
        );

      } */

    return (
      <div>
        {/* <Helmet>
                    <title>{configuration.get("configData.site_name")}</title>
                    <link
                        rel="icon"
                        type="image/png"
                        href={configuration.get("configData.site_icon")}
                        sizes="16x16"
                    />
                </Helmet> */}
        <ToastProvider>
          <Router history={history}>
            <Switch>
              {/*** Layout 1 - Transparent Fixed Header and Floating Footer ****/}
              <AppRoute
                exact
                path={"/"}
                component={LandingPage}
                layout={FirstLayout}
              />
              <AppRoute
                path={"/single-space/:id"}
                component={SingleSpace}
                layout={FirstLayout}
              />
              <AppRoute
                path={"/checkout"}
                component={Checkout}
                layout={FirstLayout}
              />
              <AppRoute
                path={"/view-all/:url_type"}
                component={ViewAll}
                layout={FirstLayout}
              />
              <UserPrivateRoute
                user_authentication={this.state.user_authentication}
                // user_authentication={this.state.user_authentication}
                path={"/profile"}
                component={Profile}
                layout={FirstLayout}
              />
              <UserPrivateRoute
                user_authentication={this.state.user_authentication}
                path={"/user-profile"}
                component={UserProfile}
                layout={FirstLayout}
              />
              <UserPrivateRoute
                user_authentication={this.state.user_authentication}
                path={"/booking-history"}
                component={BookingHistory}
                layout={FirstLayout}
              />
              <UserPrivateRoute
                user_authentication={this.state.user_authentication}
                path={"/booking-upcoming"}
                component={BookingUpcoming}
                layout={FirstLayout}
              />
              <UserPrivateRoute
                user_authentication={this.state.user_authentication}
                path={"/single-booking-history/:id"}
                component={SingleBookingHistory}
                layout={FirstLayout}
              />
              <AppRoute
                exact
                path={"/explore/:type"}
                component={ExplorePage}
                layout={FirstLayout}
              />

              <AppRoute
                path={"/provider-profile/:id"}
                component={ProviderProfile}
                layout={FirstLayout}
              />

              <Route path={"/logout"} component={Logout} />
              
              {/**
               *  For private route
               * <UserPrivateRoute user_authentication={this.state.user_authentication} path={"/subscriptions"} component={Subscription} layout={TwoColumnLayout} screenProps={this.eventEmitter}/>
               */}
            </Switch>
          </Router>
        </ToastProvider>
      </div>
    );
  }
}

export default ReactTimeout(App);
