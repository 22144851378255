import React, { Component } from "react";
import api from "../../Environment";
import Helper from "../Helper/helper";
import { withToastManager } from "react-toast-notifications";
import ToastDemo from "../Helper/toaster";
import { translate, t } from "react-multi-lang";

class Checkout extends Helper {
  state = {
    loadingCardList: true,
    cardList: null,
    data: {},
    buttonDisable: false,
    buttonLoadingText: null
  };

  componentDidMount() {
    console.log("Props", this.props.location.state.basicDetails);
    this.getCardList();
  }

  payNow = event => {
    event.preventDefault();
    const { basicDetails, pricingCalculatorInput } = this.props.location.state;
    const data = { ...this.state.data };
    data["space_id"] = basicDetails.space_id;
    data["checkin"] = pricingCalculatorInput.checkin;
    data["total_seats"] = pricingCalculatorInput.total_seats;
    data["payment_mode"] = "card";
    data["price_type"] = pricingCalculatorInput.price_type;
    data["total_days"] = pricingCalculatorInput.total_days;
    this.setState({ data });
    this.setState({
      buttonDisable: true,
      buttonLoadingText: "Loading... Please wait."
    });
    setTimeout(() => {
      this.paynowAPI();
    }, 1000);
  };

  paynowAPI = () => {
    api.postMethod("spaces_bookings_create", this.state.data).then(response => {
      if (response.data.success) {
        this.setState({
          buttonDisable: false,
          buttonLoadingText: null
        });
        ToastDemo(this.props.toastManager, response.data.message, "success");
        this.props.history.push(`/single-booking-history/${response.data.data.booking_id}`);
      } else {
        const check = this.checkLoginUser(response.data);
        if (check) {
          ToastDemo(
            this.props.toastManager,
            "Please login to save the details",
            "error"
          );
        } else {
          ToastDemo(this.props.toastManager, response.data.error, "error");
        }
      }
    });
  };

  render() {
    const {
      basicDetails,
      pricing,
      pricingCalculatorInput,
      buttonDisable,
      buttonLoadingText
    } = this.props.location.state;
    const { loadingCardList, cardList } = this.state;

    return (
      <section className="sm-padding">
        <div className="container">
          <div className="col-md-12 no-padding">
            <h2 className="title_main no-margin">{t("payment_information")}</h2>
            <i className="border-title mb-5"></i>
          </div>
          <div className="row">
            <div className="col-md-7">
              <h4 className="mb-4 card-title">{t("payment_method")}</h4>
              <div className="row payment-method">
                {loadingCardList
                  ? t("loading")
                  : cardList.cards.length > 0
                  ? cardList.cards.map(cards => (
                      <div className="col-md-12" key={cards.user_card_id}>
                        <div className="radiobtn mb-4">
                          <input
                            type="radio"
                            name="plan"
                            id="inline-radio-1"
                            className="form-check-input"
                            value="reach"
                            checked="checked"
                          />
                          <label
                            type="radio"
                            for="inline-radio-1"
                            className="form-check-label card-label"
                          >
                            <h3 className="title-head">{cards.card_name}</h3>
                            <p className="card-desc">
                              XXXX XXXX XXXX {cards.last_four}
                            </p>
                          </label>
                        </div>
                      </div>
                    ))
                  : t("payment_method_error")}
              </div>
            </div>
            <div className="col-md-5">
              <h4 className="mb-4 card-title">{t("order_summary")}</h4>
              <div className="booking-total">
                <div className="summary-booking">
                  <h5>{t("invoice")}</h5>
                </div>
                <div className="package-price">
                  <div className="row">
                    <div className="col-md-8">
                      <h5>{basicDetails.space_name}</h5>
                      <p className="mt-2">{basicDetails.space_location}</p>
                    </div>
                    <div className="col-md-4 text-right">
                      <p>
                        <strong></strong>
                      </p>
                    </div>
                  </div>

                  <div className="border-light mt-3"></div>
                  {pricingCalculatorInput.price_type == "per_day" ? (
                    <div className="row mt-3">
                      <div className="col-md-8">
                        <h5>{t("number_of_days")}</h5>
                        <p className="mt-2"></p>
                      </div>
                      <div className="col-md-4 text-right">
                        <p>
                          <strong>{pricingCalculatorInput.total_days}</strong>
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div className="row mt-3">
                      <div className="col-md-8">
                        <h5>{t("number_of_month")}</h5>
                        <p className="mt-2"></p>
                      </div>
                      <div className="col-md-4 text-right">
                        <p>
                          <strong>1</strong>
                        </p>
                      </div>
                    </div>
                  )}
                  {basicDetails.space_type == "shared_space" ? (
                    <div className="row mt-3">
                      <div className="col-md-8">
                        <h5>{t("number_of_seats")}</h5>
                        <p className="mt-2"></p>
                      </div>
                      <div className="col-md-4 text-right">
                        <p>
                          <strong>{pricingCalculatorInput.total_seats}</strong>
                        </p>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="border-light mt-3"></div>
                  <div className="row mt-3">
                    <div className="col-md-8">
                      <h5>{t("price")}</h5>
                      {/* <p className="mt-2">{basicDetails.space_location}</p> */}
                    </div>
                    <div className="col-md-4 text-right">
                      <p>
                        <strong>{pricing.sub_total_formatted}</strong>
                      </p>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-8">
                      <h5>{t("tax_price")}</h5>
                      {/* <p className="mt-2">{basicDetails.space_location}</p> */}
                    </div>
                    <div className="col-md-4 text-right">
                      <p>
                        <strong>{pricing.tax_price}</strong>
                      </p>
                    </div>
                  </div>
                  <div className="border-light mt-3"></div>
                  <div className="row mt-3">
                    <div className="col-md-8">
                      <h5>{t("grand_total")}</h5>
                      {/* <p className="mt-2">{basicDetails.space_location}</p> */}
                    </div>
                    <div className="col-md-4 text-right">
                      <p>
                        <strong>{pricing.total_formatted}</strong>
                      </p>
                    </div>
                  </div>
                  {/* <div className="row mt-3">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Promo Code</label>
                        <input
                          type="text"
                          className="form-control"
                          id="cardNumber"
                          placeholder="Enter if you have one"
                        />
                      </div>
                    </div>
                  </div> */}
                  <div className="suggestion mt-3">
                    <textarea
                      className="form-control"
                      id="exampleFormControlTextarea1"
                      rows="1"
                      placeholder={t("checkout_info")}
                      name="description"
                      onChange={this.handleChange}
                    ></textarea>
                  </div>
                  <div className="border-light mt-3"></div>
                  <div className="row mt-4">
                    <div className="col-md-8">
                      <h4 className="payable-amount">{t("payable_amount")}</h4>
                    </div>
                    <div className="col-md-4 text-right">
                      <h4 className="payable-amount">
                        {pricing.total_formatted}
                      </h4>
                    </div>
                  </div>
                  <a
                    className="btn btn-outline-primary btn-block mt-5"
                    onClick={this.payNow}
                    disable={buttonDisable}
                  >
                    {buttonLoadingText != null ? buttonLoadingText : t("pay_now")}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default withToastManager(translate(Checkout));
