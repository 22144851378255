import React, { Component } from "react";
import Helper from "../Helper/helper";
import api from "../../Environment";
import { withToastManager } from "react-toast-notifications";
import ToastDemo from "../Helper/toaster";
import { translate, t } from "react-multi-lang";

var const_time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone;

class EditProfile extends Helper {

  constructor(props) {
    super(props);

    // States and props usage
  }
  
  state = {
    data: {},
    loading: true,
    buttonDisabled: false,
    loadingContent: null
  };

  componentDidMount() {
    this.getUserDetails();
    this.setState({ data: this.props.data });
  }

  handleEditProfile = event => {
    event.preventDefault();
    this.setState({
      loadingContent: "Loading... Please wait..",
      buttonDisable: true
    });

    let userDetails = { ...this.state.data };
    const userData = {
        name: userDetails.name,
        description: userDetails.description,
        email: userDetails.email,
        mobile: userDetails.mobile,
        timezone: const_time_zone
    };

    api.postMethod("update_profile", userData).then(response => {
      if (response.data.success) {
        ToastDemo(this.props.toastManager, response.data.message, "success");
        this.setState({ loadingContent: null, buttonDisable: false });
      } else {
        ToastDemo(this.props.toastManager, response.data.error, "error");
        this.setState({ loadingContent: null, buttonDisable: false });
      }
    });
  };

  render() {
    const { data, loading } = this.state;
    return (
      <div className="row">
        <div className="col-xl-8 col-lg-8 col-md-12">
          <div className="contact">
            <form onSubmit={this.handleEditProfile}>
              <div className="form-group">
                <label>{t("name")}</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder={t("first_name")}
                  name="name"
                  value={loading ? "" : data.name}
                  onChange={this.handleChange}
                />
              </div>
              <div className="form-group">
                <label>{t("email_address")}</label>
                <input
                  type="email"
                  className="form-control"
                  placeholder={t("email_address_placeholder")}
                  name="email"
                  value={loading ? "" : data.email}
                  onChange={this.handleChange}
                />
              </div>
              <div className="form-group">
                <label>{t("phone_number")}</label>
                <input
                  id="phone"
                  type="text"
                  className="form-control"
                  placeholder={t("phone")}
                  name="mobile"
                  value={loading ? "" : data.mobile}
                  onChange={this.handleChange}
                />
              </div>
              
              <div className="row">
                <div className="col-md-12 mt-3">
                  <button
                    disabled={this.state.buttonDisabled}
                    className="btn btn-primary btn-lg btn-block"
                  >
                    {this.state.loadingContent != null
                      ? this.state.loadingContent
                      : t("save")}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default withToastManager(translate(EditProfile));
