import React, { Component } from "react";
import { withToastManager } from "react-toast-notifications";
import ToastDemo from "../Helper/toaster";
import AddSpaceLink from "../Helper/addSpaceLink";
import api from "../../Environment";
import HowItWorks from "./howItWorks";
import StaticContent from "./staticContent";
import SpaceTypeView from "./spaceTypeView";
import SpaceListView from "./spaceListView";
import Map, { GoogleApiWrapper, Marker } from "google-maps-react";
import { apiConstants } from "../Constant/constants";
import { translate, t } from "react-multi-lang";

class LandingPage extends Component {
  state = {
    loading: true,
    response: null,
    formData: {
      latitude: "",
      longitude: "",
      full_address: ""
    }
  };
  componentDidMount() {
    this.homePageApiCall();
  }

  homePageApiCall = () => {
    api.postMethod("spaces_home").then(response => {
      if (response.data.success) {
        this.setState({
          loading: false,
          response: response.data.data
        });
      } else {
      }
    });
  };

  displaySpaces = event => {
    event.preventDefault();
    if (!this.state.formData.full_address) {
      ToastDemo(this.props.toastManager, "Enter the location", "error");
      return false;
    }

    this.props.history.push(`/view-all/${3}`, this.state.formData);
  };

  renderAutoComplete = () => {
    console.log("asdfsf");
    const { google } = this.props;
    if (!google) {
      console.log("asdfsadfasdfno");
      return;
    }

    const autocomplete = new google.maps.places.Autocomplete(
      this.autocomplete,
      { types: ["geocode"] }
    );

    autocomplete.setFields(["address_component", "geometry", "name"]);

    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      console.log("Place", place.geometry);
      console.log("asdf", autocomplete.getPlace());
      if (!place.geometry) {
        console.log("sdfsd efe");
        return;
      }
      // this.setState({ position: place.geometry.location });
      const formData = { ...this.state.formData };
      formData["latitude"] = place.geometry.location.lat();
      formData["longitude"] = place.geometry.location.lng();
      let full_address = "";
      place.address_components.map(
        address =>
          (full_address =
            full_address == ""
              ? address.long_name
              : full_address + "," + address.long_name)
      );
      formData["full_address"] = full_address;

      this.setState({ formData });
      setTimeout(() => {
        this.props.history.push(`/view-all/${3}`, this.state.formData);
      }, 1000);
    });
  };

  render() {
    const { loading, response } = this.state;
    return (
      <div className="landing-page-sec">
        <section className="carousel-slider-main banner-slider">
          <div className="owl-carousel owl-theme owl-carousel-slider">
            <div className="item">
              <a href="#">
                <img
                  className="img-fluid"
                  src={window.location.origin + "/assests/img/banner1.jpg"}
                  alt=""
                />
              </a>
            </div>
          </div>
          <div className="workspace-grid">
            <div className="workspace-content">
              <h3 className="title">{t("revolutionize_your_workspace")}</h3>
              <p className="sub-title mt-3">
                {t("revolutionize_your_workspace_para")}
              </p>
              <div className="location_select mt-4">
                <label>{t("find_your_location")}</label>
                <div className="input-group mb-4">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fas fa-map-marker-alt"></i>
                    </span>
                  </div>
                  <input
                    type="text"
                    class="form-control"
                    placeholder={t("search")}
                    onFocus={this.renderAutoComplete}
                    ref={ref => (this.autocomplete = ref)}
                  ></input>
                </div>
              </div>
              <a
                className="btn btn-primary btn-block btn-lg"
                onClick={this.displaySpaces}
              >
                {t("start")}
              </a>
            </div>
          </div>
        </section>
        {loading
          ? t("loading")
          : response.map((data, index) => (
              <React.Fragment>
                {index == 0 ? (
                  <React.Fragment>
                    <SpaceTypeView
                      spaceType={response[index]}
                      parameter={data.key}
                    />

                    <StaticContent />
                  </React.Fragment>
                ) : (
                  ""
                )}
                {index == 1 ? (
                  response[index].data.length > 0 ? (
                    <SpaceListView spaceList={response[index]} />
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}

                {index == 2 ? (
                  response[index].data.length > 0 ? (
                    <SpaceListView spaceList={response[index]} />
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </React.Fragment>
            ))}
        <HowItWorks />
        <AddSpaceLink />
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: apiConstants.google_api_key
})(withToastManager(translate(LandingPage)));
