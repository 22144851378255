import React, { Component } from "react";
import { Link } from "react-router-dom";
import api from "../../Environment";
import StarRatingComponent from "react-star-rating-component";
import { apiConstants } from "../Constant/constants";

class Helper extends Component {
  state = {
    data: {},
    profileError: null,
    loading: true,
    loadingCardList: true,
    cardList: null,
    first_block: null,
    mainData: null,
    userDetails: {},
    errors: {},
    isMapVisible: false,
    loadingCategory: true,
    loadingProfile: true,
    profileError: null,
    skipCount: 0,
    loadingStatus: true,
    loadingContent: null,
    contentData: null,
    second_block: null,
    profileUpdateStatusContent: null,
    changeChat: false
  };

  handleChange = ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data });
  };

  getUserDetails() {
    api.postMethod("profile").then(response => {
      if (response.data.success === true) {
        this.setState({ data: response.data.data, loading: false });
      } else {
        const check = this.checkLoginUser(response.data);
        if (check) {
          this.setState({ profileError: "Please login to continue" });
        } else {
          this.setState({ profileError: response.data.error });
        }
      }
    });
  }

  getProviderProfile(id) {
    api.postMethod("providers_view", { provider_id: id }).then(response => {
      if (response.data.success === true) {
        this.setState({ data: response.data.data, loading: false });
      } else {
        // Do nothing.
      }
    });
  }
  
  checkLoginUser(data) {
    return apiConstants.ERROR_CODE.includes(data.error_code);
  }
  getCardList() {
    api.postMethod("cards_list").then(response => {
      if (response.data.success) {
        this.setState({
          cardList: response.data.data,
          loadingCardList: false
        });
      } else {
        const check = this.checkLoginUser(response.data);
        if (check) {
          this.setState({ profileError: "Please login to continue" });
        } else {
          this.setState({ profileError: response.data.error });
        }
      }
    });
  }
  seeAll = (inputData, loadMore) => {
    let items;
    let skipcountTemp;
    if (loadMore == "loadMore") {
      // do nothing.
    } else {
      this.setState({ loading: true, contentData: null, skipCount: 0 });
    }
    api.postMethod("spaces_see_all", inputData).then(response => {
      if (response.data.success && response.data.data != "") {
        if (loadMore == "loadMore") {
          if (this.state.contentData != null) {
            items = [...this.state.contentData, ...response.data.data.data];
          } else {
            items = [...response.data.data.data];
          }
        } else {
          items = [...response.data.data.data];
        }
        skipcountTemp = response.data.data.data.length;
        this.setState({
          contentData: items,
          mainData: response.data,
          loading: false,
          skipCount: skipcountTemp + this.state.skipCount,
          loadingStatus: true,
          first_block: response.data.first_block
        });
      }
    });
  };
  renderAutoComplete = () => {
    console.log("asdfsf");
    const { google } = this.props;
    if (!google) {
      console.log("asdfsadfasdfno");
      return;
    }

    const autocomplete = new google.maps.places.Autocomplete(
      this.autocomplete,
      { types: ["geocode"] }
    );

    autocomplete.setFields(["address_component", "geometry", "name"]);

    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      console.log("Place", place.geometry);
      console.log("asdf", autocomplete.getPlace());
      if (!place.geometry) {
        console.log("sdfsd efe");
        return;
      }
      // this.setState({ position: place.geometry.location });
      const formData = { ...this.state.formData };
      formData["latitude"] = place.geometry.location.lat();
      formData["longitude"] = place.geometry.location.lng();
      let full_address = "";
      place.address_components.map(
        address =>
          (full_address =
            full_address == ""
              ? address.long_name
              : full_address + "," + address.long_name)
      );
      formData["full_address"] = full_address;

      this.setState({ formData });
    });
  };

  starRatingHost(ratings, givenfontSize = 18) {
    console.log(ratings, givenfontSize);
    return (
      <span style={{ fontSize: givenfontSize }}>
        <StarRatingComponent
          name="ratings"
          editing={false}
          renderStarIcon={() => <span><i class="fa fa-star"></i></span>}
          starCount={5}
          value={ratings}
        />
      </span>
    );
  }

}

export default Helper;
