import React, { Component } from "react";

class AddSpaceLink extends Component {
  state = {};
  render() {
    return localStorage.getItem("userLoginStatus") ? (
      ""
    ) : (
      <section className="padding-btm">
        <div className="container bg-color3 border-radius">
          <div className="row call-action">
            <div className="col-md-8 center">
              <h3 className="call_action_title">
                List your office space with us
              </h3>
              <p className="call_action_desc">
                We are experts in driving enquiries and fillin vacant workspace.
              </p>
            </div>
            <div className="col-md-4 text-right mt-2 center">
              <a href="#" className="btn btn-white">
                List Your Space
              </a>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default AddSpaceLink;
