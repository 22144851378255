import React, { Component } from "react";
import { translate, t } from "react-multi-lang";

class HowItWorks extends Component {
  state = {};
  render() {
    return (
      <section className="sm-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="title_main no-margin">{t("how_it_works")}?</h2>
              <i className="border-title"></i>
            </div>
          </div>
          <div className="row justify-content-md-center">
            <div className="col-xl-4 col-lg-4 col-md-6 resp-mt-1">
              <div className="card-content text-center">
                <div className="card-icon">
                  <img
                    src={window.location.origin + "/assests/img/list.png"}
                    alt=""
                  />
                </div>
                <div className="card-details">
                  <h4 className="card-title">{t("list_your_spaces")}</h4>
                  <p className="card-desc">
                    {t("list_your_spaces_para")}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 resp-mt-1">
              <div className="card-content text-center">
                <div className="card-icon">
                  <img
                    src={window.location.origin + "/assests/img/order.png"}
                    alt=""
                  />
                </div>
                <div className="card-details">
                  <h4 className="card-title">{t("get_more_orders")}</h4>
                  <p className="card-desc">
                    {t("get_more_orders_para")}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 resp-mt-1">
              <div className="card-content text-center">
                <div className="card-icon">
                  <img
                    src={window.location.origin + "/assests/img/money_icon.png"}
                    alt=""
                  />
                </div>
                <div className="card-details">
                  <h4 className="card-title">{t("earn_money")}</h4>
                  <p className="card-desc">
                    {t("earn_money_para")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default translate(HowItWorks);
