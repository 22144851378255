import React, { Component } from "react";
import { DatePicker } from "@y0c/react-datepicker";
import dayjs from "dayjs";
import api from "../../Environment";
import { withToastManager } from "react-toast-notifications";
import ToastDemo from "../Helper/toaster";
import { Link } from "react-router-dom";
import { translate, t } from "react-multi-lang";

class BookingForm extends Component {
  state = {
    priceTypeText: "Days/Months",
    formData: {
      checkin: "",
      checkout: ""
    },
    pricing: null,
    loading: true,
    pricingCalculatorInput: {
      price_type: "",
      total_days: "",
      total_seats: "",
      checkin: "",
      space_id: ""
    },
    loadingText: "",
    buttonDisable: false,
    buttonLoadingText: null
  };

  componentDidMount() {
    this.getDefaultLeavingTime();
    const pricingCalculatorInput = { ...this.state.pricingCalculatorInput };
    pricingCalculatorInput["space_id"] = this.props.basicDetails.space_id;
    this.setState({ pricingCalculatorInput });
  }

  handleChange = ({ currentTarget: input }) => {
    const pricingCalculatorInput = { ...this.state.pricingCalculatorInput };
    pricingCalculatorInput[input.name] = input.value;
    this.setState({ pricingCalculatorInput });
    setTimeout(() => {
      this.checkValidation();
    }, 1000);
  };

  checkValidation = () => {
    if (this.state.pricingCalculatorInput["checkin"] != "") {
      if (this.props.basicDetails.space_type == "shared_space") {
        if (
          this.state.pricingCalculatorInput["price_type"] == "per_day" &&
          this.state.pricingCalculatorInput["total_days"] != "" &&
          this.state.pricingCalculatorInput["total_seats"] != ""
        ) {
          this.setState({ loadingText: "Loading..." });
          this.pricingCalculator();
          // setTimeout(() => {
          //   this.pricingCalculator();
          // }, 1000);
          return true;
        } else if (
          this.state.pricingCalculatorInput["price_type"] == "per_month" &&
          this.state.pricingCalculatorInput["total_seats"] != ""
        ) {
          this.setState({ loadingText: "Loading..." });
          this.pricingCalculator();
          return true;
        } else {
          return null;
        }
      } else {
        this.setState({ loadingText: "Loading..." });
        this.pricingCalculator();

        return true;
      }
    } else {
      return null;
    }
  };

  pricingCalculator() {
    this.setState({
      buttonDisable: true,
      buttonLoadingText: "Loading... Please wait."
    });
    api
      .postMethod("price_calculator", this.state.pricingCalculatorInput)
      .then(response => {
        if (response.data.success) {
          this.setState({
            pricing: response.data.data,
            loading: false,
            loadingText: "",
            buttonDisable: false,
            buttonLoadingText: null
          });
        } else {
          ToastDemo(this.props.toastManager, response.data.error, "error");
        }
      });
  }

  getArrivingDateandTime = title => (...args) => {
    const pricingCalculatorInput = { ...this.state.pricingCalculatorInput };
    const datess = dayjs(args[0]).format("YYYY-MM-DD HH:mm:ss");
    pricingCalculatorInput["checkin"] = datess;
    this.setState({ pricingCalculatorInput });
    setTimeout(() => {
      this.checkValidation();
    }, 1000);
  };

  getDefaultLeavingTime = () => {
    var oldDateObj = new Date();
    var newDateObj = new Date();
    newDateObj.setTime(oldDateObj.getTime() + 60 * 60 * 1000);

    return dayjs(newDateObj).format("YYYY-MM-DD HH:mm");
  };
  getDefaultArravingTime = () => {
    return dayjs(new Date()).format("YYYY-MM-DD HH:mm");
  };

  priceType = value => {
    this.setState({ priceTypeText: value });
    const pricingCalculatorInput = { ...this.state.pricingCalculatorInput };
    if (value == "Days") pricingCalculatorInput["price_type"] = "per_day";
    else pricingCalculatorInput["price_type"] = "per_month";
    this.setState({ pricingCalculatorInput });
    setTimeout(() => {
      this.checkValidation();
    }, 1000);
  };

  sendCheckout = event => {
    event.preventDefault();
    const value = this.checkValidation();
    if (value != null && this.state.pricing != null) {
      if (localStorage.getItem("userLoginStatus"))
        this.props.props.history.push("/checkout", {
          pricing: this.state.pricing,
          basicDetails: this.props.basicDetails,
          pricingCalculatorInput: this.state.pricingCalculatorInput
        });
      else
        ToastDemo(this.props.toastManager, "Please login to continue", "error");
    } else {
      ToastDemo(this.props.toastManager, "Please fill all the details", "error");
    }
  };

  render() {
    const {
      priceTypeText,
      loading,
      pricing,
      pricingCalculatorInput,
      loadingText,
      buttonDisable,
      buttonLoadingText
    } = this.state;
    return (
      <div className="col-md-5">
        <div className="contact">
          <div class="form-row">
            <div className="form-group col-md-6">
              <div className="custom-control custom-radio custom-control-inline">
                <input
                  type="radio"
                  id="perday"
                  name="price"
                  value="per_day"
                  className="custom-control-input"
                  onClick={() => this.priceType("Days")}
                />
                <label className="custom-control-label" for="perday">
                  {t("per_day")}{" "}
                </label>
              </div>
            </div>
            <div className="form-group col-md-6">
              <div className="custom-control custom-radio custom-control-inline">
                <input
                  type="radio"
                  id="permonth"
                  name="price"
                  value="per_month"
                  className="custom-control-input"
                  onClick={() => this.priceType("Months")}
                />
                <label className="custom-control-label" for="permonth">
                  {t("per_month")}
                </label>
              </div>
            </div>
          </div>
          {priceTypeText == "Days" ? (
            <div className="form-group">
              <label>{t("how_many_days")}</label>
              <input
                type="number"
                className="form-control"
                name="total_days"
                min="0"
                placeholder={priceTypeText}
                value={pricingCalculatorInput["total_days"]}
                onChange={this.handleChange}
              />
            </div>
          ) : (
            ""
          )}
          {this.props.basicDetails.space_type == "shared_space" ? (
            <div className="form-group">
              <label>{t("how_many_seats")}</label>
              <input
                type="number"
                className="form-control"
                name="total_seats"
                min="0"
                placeholder={t("no_of_seats")}
                value={pricingCalculatorInput["total_seats"]}
                onChange={this.handleChange}
              />
            </div>
          ) : (
            t("booking_space_para") +
            this.props.basicDetails.seats_count
          )}
          <div class="form-row">
            <div className="form-group col-md-12">
              <label>{t("check_in_date")}</label>
              {/* <input type="phone" placeholder="Date" /> */}
              <DatePicker
                initialDate={this.getDefaultArravingTime()}
                onChange={this.getArrivingDateandTime("Range DatePicker")}
                showDefaultIcon
                placeholder
              />
            </div>

            {/* <div className="form-group col-md-6">
                <button className="btn btn-primary">Calculate</button>
              </div> */}
          </div>
          {loading ? (
            ""
          ) : (
            <React.Fragment>
              <div className="row">
                <div className="form-group col-md-6">
                  <label>{t("sub_total")}</label>
                </div>
                <div className="col-md-6">
                  <label>
                    {loadingText != ""
                      ? loadingText
                      : pricing.sub_total_formatted}
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="form-group col-md-6">
                  <label>{t("tax_price")}</label>
                </div>
                <div className="col-md-6">
                  <label>
                    {loadingText != "" ? loadingText : pricing.tax_price}
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="form-group col-md-6">
                  <label>{t("total_amount_payable")}</label>
                </div>
                <div className="col-md-6">
                  <label>
                    {loadingText != "" ? loadingText : pricing.total_formatted}
                  </label>
                </div>
              </div>
            </React.Fragment>
          )}
          {/* <div className="form-group">
              <label>{t("payment_mode")}</label>
              <select class="custom-select custom-select-lg mb-3">
                <option className="special selected" value="1">
                  {t("card")}
                </option>
                <option value="2">{t("paypal")}</option>
              </select>
            </div> */}
          <div className="row">
            <div className="col-md-12 mt-3">
              <Link
                to={{
                  pathname: "/checkout",
                  state: {
                    pricing: pricing,
                    basicDetails: this.props.basicDetails
                  }
                }}
                onClick={this.sendCheckout}
                disable={buttonDisable}
                className="btn btn-primary btn-lg btn-block"
              >
                {buttonLoadingText != null ? buttonLoadingText : t("book_now")}
              </Link>
            </div>
          </div>

          <p className="mt-3 card-desc">
            {t("booking_terms_para1")}{" "}
            <a href="#" className="orange">
              {t("terms_of_service")}
            </a>{" "}
            {t("booking_terms_para2")}{" "}
            <a className="orange" href="#">
              {" "}
              {t("privacy_policy")}
            </a>
          </p>
          <h4 className="card-title mt-3">
            {t("cowork_para1")}
          </h4>
          <p className="card-desc mt-2">
            {t("cowork_para2")}{" "}
            <a href="#" className="orange">
              1800 1234 567 890
            </a>
          </p>
        </div>
      </div>
    );
  }
}

export default withToastManager(translate(BookingForm));
