import React, { Component } from "react";
import { translate, t } from "react-multi-lang";

class OpenSection extends Component {
  state = {};
  render() {
    const { spaceOpeningTimings } = this.props;
    return (
      <div className="col-md-12 mt-5">
        <h2 className="title_main no-margin">{t("our_opening_hours")}</h2>
        <i className="border-title mb-5"></i>
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">{t("days")}</th>
              <th scope="col" className="text-center">
                {t("opening")}
              </th>

              <th scope="col" className="text-center">
                {t("closing")}
              </th>
            </tr>
          </thead>
          <tbody>
            {spaceOpeningTimings.length > 0
              ? spaceOpeningTimings.map(timings => (
                  <tr>
                    <th scope="row">{timings.day}</th>
                    <td>{timings.in_time ? timings.in_time : 'Closed'}</td>

                    <td>{timings.out_time ? timings.out_time : 'Closed'}</td>
                  </tr>
                ))
              : t("no_data")}
          </tbody>
        </table>
      </div>
    );
  }
}

export default translate(OpenSection);
