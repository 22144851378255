import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

class SingleSpaceImageSlider extends Component {
  state = {};
  render() {
    const { gallery } = this.props;
    return (
      <div className="col-md-12" id="tab-img-slider">
        <div
          id="carousel-custom"
          className="carousel slide"
          data-ride="carousel"
        >
          <div className="carousel-inner">
            <Carousel
              showThumbs={false}
              infiniteLoop={true}
              showStatus={false}
              showArrows={true}
              showIndicators={true}
              autoPlay={false}
              stopOnHover={true}
              swipeable={true}
            >
              {gallery.length > 0
                ? gallery.map(images => (
                    <div className="carousel-item active">
                      <img src={images.picture} className="tab-big-img" />
                    </div>
                  ))
                : ""}
            </Carousel>
          </div>
        </div>
      </div>
    );
  }
}

export default SingleSpaceImageSlider;
