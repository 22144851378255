import React, { Component } from "react";
import { injectStripe, CardElement } from "react-stripe-elements";
import { withToastManager } from "react-toast-notifications";
import ToastDemo from "../Helper/toaster";
import api from "../../Environment";
import { Link } from "react-router-dom";
import { translate, t } from "react-multi-lang";

class Cards extends Component {
  state = {
    displayAddCard: "none",
    data: {
      card_number: "",
      month: "",
      year: "",
      cvv: ""
    },
    loadingContent: null,
    buttonDisable: false
  };
  addCard = ev => {
    ev.preventDefault();
    this.setState({
      loadingContent: "Loading... Please wait..",
      buttonDisable: true
    });
    if (this.props.stripe) {
      this.props.stripe
        .createToken({
          type: "card",
          name: localStorage.getItem("username")
        })
        .then(payload => {
          if (payload.error) {
            ToastDemo(this.props.toastManager, payload.error.message, "error");
            this.setState({
              loadingContent: null,
              buttonDisable: false
            });
          } else {
            const inputData = {
              card_token: payload.token.id
            };
            api
              .postMethod("cards_add", inputData)
              .then(response => {
                if (response.data.success) {
                  ToastDemo(
                    this.props.toastManager,
                    response.data.message,
                    "success"
                  );
                  this.setState({
                    loadingContent: null,
                    buttonDisable: false
                  });
                  this.props.history.push("/user-profile");
                } else {
                  ToastDemo(
                    this.props.toastManager,
                    response.data.error_message,
                    "error"
                  );
                  this.setState({
                    loadingContent: null,
                    buttonDisable: false
                  });
                }
              })
              .catch(error => {
                ToastDemo(this.props.toastManager, error, "error");
                this.setState({
                  loadingContent: null,
                  buttonDisable: false
                });
              });
          }
        });
    } else {
      ToastDemo(
        this.props.toastManager,
        "Stripe.js hasn't loaded yet.",
        "error"
      );
      this.setState({ loadingContent: null, buttonDisable: false });
    }
  };

  setDefaultCard = (event, card) => {
    event.preventDefault();
    api.postMethod("cards_default", {
        user_card_id: card.user_card_id
    }).then(response => {
        if (response.data.success) {
            ToastDemo(
                this.props.toastManager,
                response.data.message,
                "success"
            );
            // this.listCardApi();
        } else {
            ToastDemo(
                this.props.toastManager,
                response.data.error,
                "error"
            );
        }
    });
  };

  deleteCard = (event, card) => {
      event.preventDefault();
      api.postMethod("cards_delete", {
          user_card_id: card.user_card_id
      }).then(response => {
          if (response.data.success) {
              ToastDemo(
                  this.props.toastManager,
                  response.data.message,
                  "success"
              );
              // this.listCardApi();
          } else {
              ToastDemo(
                  this.props.toastManager,
                  response.data.error,
                  "error"
              );
          }
      });
  };

  displayAddCard = () => {
    this.setState({ displayAddCard: "block" });
  };
  render() {
    const { cardList } = this.props;

    return (
      <React.Fragment>
        {" "}
        <div className="row">
          <div className="col-md-12">
            <div className="row">
              {cardList.cards.length > 0
                ? cardList.cards.map(card => (
                    <div className="col-xl-4 col-lg-4 col-md-6 resp-margin-btm-xs" key={card.user_card_id}>
                      <div className="paid-details">
                        <h3 className="card-title">{card.card_name}</h3>
                        <p className="card-dec">
                          XXXX XXXX XXXX {card.last_four}
                        </p>
                        <div className="payment-bottom">
                          {card.is_default ==
                          1 ? (
                              <p className="captalize m-0">
                                  {t("default_card")}
                              </p>
                          ) : (
                            <div>
                                <Link
                                    to="#"
                                    onClick={event =>
                                        this.setDefaultCard(
                                            event,
                                            card
                                        )
                                    }
                                >
                                    <p className="red-text1 m-0">
                                        {t("set_as_default")}
                                    </p>
                                </Link>
                                <Link
                                    to="#"
                                    onClick={event =>
                                        this.deleteCard(
                                            event,
                                            card
                                        )
                                    }
                                >
                                    <p className="captalize m-0">
                                        remove
                                    </p>{" "}
                                </Link>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ))
                : ""}
              <div className="col-xl-4 col-lg-4 col-md-6">
                <div className="add-payment">
                  <a
                    className="no-margin"
                    href="#"
                    onClick={this.displayAddCard}
                  >
                    <i className="fas fa-plus plus-icon mr-2"></i>
                    <span className="card-option">{t("add_a_payment_method")}</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-12">
            <div
              className="card-pay"
              style={{ display: this.state.displayAddCard }}
            >
              <form onSubmit={this.addCard}>
                <h4 className="card-title">{t("payment")}</h4>
                <div className="card-img">
                  <img
                    src={window.location.origin + "/assests/img/cards.png"}
                    alt="card payment"
                  />
                </div>
                <CardElement />

                {/* <div className="row mt-3">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="cardNumber">Card Number</label>
                      <input
                        type="text"
                        className="form-control"
                        id="cardNumber"
                        placeholder="Enter Card Number"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="name">Name On Card</label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        placeholder="Enter Name"
                      />
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="date">Expiry Date</label>
                      <input
                        type="text"
                        className="form-control"
                        id="date"
                        placeholder="MM / YYYY"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="cvv">CVV / Security Code</label>
                      <input
                        type="text"
                        className="form-control"
                        id="cvv"
                        placeholder="CVV"
                      />
                    </div>
                  </div>
                </div>
                 */}
                <div className="row mt-5">
                  <div className="col-md-12">
                    <a className="btn btn-outline-primary mr-2 cancel" href="#">
                      Cancel
                    </a>
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={this.state.buttonDisable}
                    >
                      {this.state.loadingContent != null
                        ? this.state.loadingContent
                        : t("add_card")}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default injectStripe(translate(withToastManager(Cards)));
