import React, { Component } from "react";
import SimilarSpace from "../Helper/similarSpace";
import AddSpaceLink from "../Helper/addSpaceLink";
import api from "../../Environment";
import SingleSpaceMapView from "./singleSpaceMapView";
import Amenities from "./amenities";
import ReviewSection from "./reviewSection";
import OpenSection from "./openSection";
import SingleSpaceImageSlider from "./singleSpaceImageSlider";
import BookingForm from "./bookingForm";
import { relativeTimeRounding } from "moment";
import SpaceAvailablity from "./SpaceAvailablity";
import renderHTML from "react-render-html";
import { translate, t } from "react-multi-lang";

class SingleSpace extends Component {
  state = { loading: true, response: null };
  componentDidMount() {
    this.getSingleSpaceDetails(this.props.match.params.id);
  }

  //WARNING! To be deprecated in React v17. Use new lifecycle static getDerivedStateFromProps instead.
  componentWillReceiveProps(nextProps) {
    this.setState({ loading: true });
    this.getSingleSpaceDetails(nextProps.match.params.id);
  }

  getSingleSpaceDetails = id => {
    api.postMethod("spaces_view", { space_id: id }).then(response => {
      if (response.data.success) {
        this.setState({
          loading: false,
          response: response.data.data
        });
      } else {
      }
    });
  };
  render() {
    const { loading, response } = this.state;
    return (
      <div>
        {loading ? (
          t("loading")
        ) : (
          <div>
            <section className="sm-padding">
              <div className="container">
                <div className="row mb-5 row-margin-zero">
                  <div className="col-md-12">
                    <h2 className="title_main no-margin">
                      {response.basic_details.space_name}
                    </h2>
                    <i className="border-title"></i>
                    <p className="highlight-txt">
                    {response.basic_details.space_type == "private_space"
                      ? t("its_private_space")
                      : ""}
                    {response.basic_details.space_type == "shared_space"
                      ? t("its_shared_space")
                      : ""}
                  </p>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-md-6">
                    <h6 className="view-location">
                      {t("single_space_para")}
                    </h6>
                  </div>
                  <div className="col-md-6 text-right">
                    <p className="address">
                      {response.basic_details.space_location}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-7">
                    <div className="row">
                      <SingleSpaceImageSlider
                        gallery={response.space_gallery}
                      />

                      <div className="col-md-12 mt-4">
                        <h3 className="card-title">
                          {t("finding_office_space_at")}{" "}
                          {response.basic_details.space_location}
                        </h3>
                        <p className="card-desc">
                          {renderHTML(response.basic_details.space_description)}
                        </p>
                      </div>

                      <div className="col-md-12 mt-4">
                        <h2 className="title_main no-margin">
                          {t("workspace_pricing")}
                        </h2>
                        <div className="row mt-4">
                          <div className="col-md-12 mb-5">
                            <div className="workspace-card only-pricing">
                              <a href="#">
                                <p className="card-desc border-left-price">
                                  <span>{t("per_day")}</span>
                                  <strong>
                                    <span className="float-right">
                                      {response.basic_details.per_day_formatted}
                                    </span>
                                  </strong>
                                </p>
                                <p className="card-desc no-margin border-left-price">
                                  {t("per_month")}{" "}
                                  <strong>
                                    <span className="float-right">
                                      {
                                        response.basic_details
                                          .per_month_formatted
                                      }
                                    </span>
                                  </strong>
                                </p>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>

                      <SpaceAvailablity
                        space_id={response.basic_details.space_id}
                      />

                      <div className="col-md-12 mt-4">
                        <h2 className="title_main no-margin">{t("location")}</h2>
                        <i className="border-title mb-3"></i>
                        <p className="address mb-5">
                          {response.basic_details.space_location}
                        </p>
                        <div
                          className="map map-single-space"
                          style={{ width: "100%;", height: "400px" }}
                        >
                          <SingleSpaceMapView
                            lat={response.basic_details.latitude}
                            long={response.basic_details.longitude}
                          />
                        </div>
                      </div>

                      <Amenities amenties={response.amenties} />

                      <ReviewSection details={response.basic_details} />

                      <OpenSection
                        spaceOpeningTimings={response.space_opening_timings}
                      />
                    </div>
                  </div>

                  <BookingForm
                    props={this.props}
                    basicDetails={response.basic_details}
                  />
                </div>
              </div>
            </section>
            <SimilarSpace
              spaceId={response.basic_details.space_id}
              pageType="singleVideoPage"
            />
          </div>
        )}

        <AddSpaceLink />
      </div>
    );
  }
}

export default translate(SingleSpace);
