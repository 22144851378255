import React, { Component } from "react";

class Amenities extends Component {
  state = {};
  render() {
    const { amenties } = this.props;
    return (
      <div className="col-md-12 mt-5">
        <h2 className="title_main no-margin">{amenties.title}</h2>
        <i className="border-title mb-5"></i>
        <div className="row">
          {amenties.data.length > 0
            ? amenties.data.map(amentie => (
                <div className="col-md-4 mb-5">
                  <div className="amenities text-center">
                    <div className="amenities-img">
                      <img src={amentie.picture} />
                    </div>
                    <h4 className="amenities-title">{amentie.name}</h4>
                  </div>
                </div>
              ))
            : "No Amenties"}
        </div>
      </div>
    );
  }
}

export default Amenities;
