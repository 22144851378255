import React, { Component } from "react";
import Helper from "../Helper/helper";
import { Link } from "react-router-dom";
import { translate, t } from "react-multi-lang";

class Profile extends Helper {
  state = {
    data: {},
    loading: true
  };
  componentDidMount() {
    this.getUserDetails();
  }
  render() {
    const { data, loading } = this.state;
    return (
      <section className="sm-padding">
        <div className="container">
          <div className="row mb-5 row-margin-zero">
            <div className="col-md-12 text-center">
              <h2 className="title_main no-margin">{t("profile")}</h2>
              <i className="border-title mx-auto"></i>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="profile-card">
                {loading ? (
                  t("loading")
                ) : (
                  <div className="row">
                    <div className="profile-desc col-md-6">
                      <div className="profile-img">
                        <img src={data.picture} alt="user" />
                      </div>
                      <div className="profile-details">
                        <h4 className="user-name">{data.name}</h4>
                        <p className="card-desc">
                          <strong>{t("created")}</strong> - {data.created_at}
                        </p>
                        <Link to={"/user-profile"} className="edit">
                          {t("edit_profile")}
                        </Link>
                      </div>
                    </div>
                    <div className="verified col-md-6">
                      <h4 className="card-title mb-3">{t("verified_information")}</h4>
                      <p className="card-desc mb-2">
                        {t("email_address")}{" "}
                        <span className="float-right">
                          <i className="far fa-check-circle text-success"></i>
                        </span>
                      </p>
                      <p className="card-desc">
                        {t("phone_number")}
                        <span className="float-right">
                          <i className="far fa-check-circle text-success"></i>
                        </span>
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default translate(Profile);
